import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import {ApiService} from '../services/api.service'
import { AuthService } from '../services/auth.service';

import * as TradingView from '../../assets/charting_library/datafeed-api';
import * as ChartingLibrary from '../../assets/charting_library/charting_library';

import {EOD} from './event-constants'

function processEpochDate(date) {
    return [`${date.getUTCFullYear()}`, `0${date.getUTCMonth()+1}`.substr(-2), `0${date.getUTCDate()}`.substr(-2)].join("-");
}

function alignCountBack(from,to,def,countBack)
{
    let dateString="";
    let dt = new Date(to*1000);
    let offsetdt = new Date();
    if (def=="D")
    {
        offsetdt = new Date(dt.getTime() - ((countBack+Math.ceil(countBack))*86400*1000));
    }
    else if (def=="W")
    {
        offsetdt = new Date(dt.getTime() - ((countBack+Math.ceil(countBack))*604800*1000));
    }
    else if (def=="M")
    {
        offsetdt = new Date(dt.getTime() - ((countBack+Math.ceil(countBack))*2592000*1000));
    }

    dt.setDate(dt.getDate()-countBack);
    dateString = processEpochDate(offsetdt);

    return dateString;
}

async function getAllSymbols() {
	const data = await makeApiRequest('exchange-symbol-list/US?&api_token'+EOD.APIKey+"&fmt=json");
	let allSymbols:any = [];

	return allSymbols;
}

// Make requests to CryptoCompare API
export async function makeApiRequest(path) {
    try {
        const response = await fetch(`https://eodhistoricaldata.com/api/${path}`);
        return response.json();
    } catch(error) {
        throw new Error(`EOD request error: error`);
    }
}

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
    const short = `${fromSymbol}/${toSymbol}`;
    return {
        short,
        full: `${exchange}:${short}`,
    };
}

export function parseFullSymbol(fullSymbol) {
    const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
    if (!match) {
        return null;
    }

    return { exchange: match[1], fromSymbol: match[2], toSymbol: match[3] };
}



const lastBarsCache = new Map();

const configurationData = {
	supported_resolutions: [
        '1D' as TradingView.ResolutionString,
        '1W' as TradingView.ResolutionString,
        '1M'as TradingView.ResolutionString
    ],
    supports_timescale_marks:true,
	exchanges: [{
		value: 'USA',
		name: 'USA',
		desc: 'USA',
	},
	{
		// `exchange` argument for the `searchSymbols` method, if a user selects this exchange
		value: 'USA',

		// filter name
		name: 'USA',

		// full exchange name displayed in the filter popup
		desc: 'US STOCKS',
	},
	],
	symbols_types: [{
		name: 'stock',

		// `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
		value: 'stock',
	},
		// ...
	],
};



@Injectable()
export class TradingViewEodProvider implements TradingView.IDatafeedChartApi,TradingView.IExternalDatafeed,ChartingLibrary.IExternalSaveLoadAdapter {

    private _apiService: ApiService;
    private _authService: AuthService;

    constructor(
        apiService: ApiService,
        authService:AuthService
    ) {
        this._apiService = apiService;
        this._authService = authService;
    }



    getAllCharts(): Promise<ChartingLibrary.ChartMetaInfo[]>
    {
        return new Promise(resolve => {
            resolve([]);
        });
    }

	removeChart<T extends number | string>(id: T): Promise<void>
    {
        return new Promise(resolve => {
            resolve();
        });
    }

	saveChart(chartData: ChartingLibrary.ChartData): Promise<number>
    {
        return new Promise(resolve => {
            resolve(5);
        });
    }

	getChartContent(chartId: number): Promise<string>
    {
        return new Promise(resolve => {
            resolve("");
        });
    }

	getAllStudyTemplates(): Promise<ChartingLibrary.StudyTemplateMetaInfo[]>
    {
        return new Promise(resolve => {
            resolve([]);
        });
    }

	removeStudyTemplate(studyTemplateInfo: ChartingLibrary.StudyTemplateMetaInfo): Promise<void>
    {
        return new Promise(resolve => {
            resolve();
        });
    }

	saveStudyTemplate(studyTemplateData: ChartingLibrary.StudyTemplateData): Promise<void>
    {
        return new Promise(resolve => {
            resolve();
        });
    }

	getStudyTemplateContent(studyTemplateInfo: ChartingLibrary.StudyTemplateMetaInfo): Promise<string>
    {
        return new Promise(resolve => {
            resolve("");
        });
    }

	getDrawingTemplates(toolName: string): Promise<string[]>
    {
        return new Promise(resolve => {
            resolve([]);
        });
    }

	loadDrawingTemplate(toolName: string, templateName: string): Promise<string>
    {
        return new Promise(resolve => {
            resolve("");
        });
    }

	removeDrawingTemplate(toolName: string, templateName: string): Promise<void>
    {
        return new Promise(resolve => {
            resolve();
        });
    }

	saveDrawingTemplate(toolName: string, templateName: string, content: string): Promise<void>
    {
        return new Promise(resolve => {
            resolve();
        });
    }

    public async getTimescaleMarks(
        symbolInfo: TradingView.LibrarySymbolInfo,
        from: number,
        to: number,
        onDataCallback: TradingView.GetMarksCallback<TradingView.TimescaleMark>,
        resolution: TradingView.ResolutionString)
    {

      let param = {
        "symbol" : symbolInfo.name
      }


      this._apiService.getStockTimeMarks(param).subscribe(data => {
          if (!this._authService.authCheckApi(data)) return;
          if (data.code==0)
          {
            let result = data.result;

            result = result.map ((item) => {
              //let msg = ["Earnings"];
              let msg = Array();
              if (item.reportDate != null)
                msg.push ("Earnings Reported Date: "+ item.reportDate);

            if (item.beforeAfterMarket==null)
                msg.push ("Before/After Market: Unknown");
            else
                msg.push ("Before/After Market: "+ item.beforeAfterMarket.replace(/([A-Z])/g, ' $1').trim());


              let mark = {
                id : symbolInfo.name + "#" + item.date,
                time : new Date(item.reportDate).getTime()/1000,
                color:"#FF0000",
                label:"E",
                tooltip: msg
              }
              return mark;

            });

            onDataCallback(result);
          }
      });

    }


	public async searchSymbols(
        userInput: string,
        exchange: string,
        symbolType: string,
        onResult: TradingView.SearchSymbolsCallback)
    {

        var newSymbols = Array();

        let param = {
            "search" : userInput.toUpperCase()
        }


        this._apiService.getStockAutocomplete(param).subscribe(data => {
            if (!this._authService.authCheckApi(data)) return;
            if (data.code==0)
            {
                for (var stock of data.result)
                {
                    let stDetail = {
                        symbol: stock.General_Code,
                        full_name: "USA:"+stock.General_Code,
                        //full_name: stock.General_Code,
                        description: stock.General_Name,
                        exchange: "USA",
                        type: 'stock',
                    }
                    newSymbols.push(stDetail);
                }
                onResult(newSymbols);
            }
        });

    }


	public async resolveSymbol(
        symbolName: string,
        onResolve: TradingView.ResolveCallback,
        onError: TradingView.ErrorCallback,
        extension?: TradingView.SymbolResolveExtension
        )
    {
        var tz:TradingView.Timezone = "America/New_York";
        var sf:TradingView.SeriesFormat = "price";
        var rs:TradingView.ResolutionString[] =
        [
            '1D' as TradingView.ResolutionString,
            '1W' as TradingView.ResolutionString,
            '1M'as TradingView.ResolutionString
        ];

        if (!symbolName.includes("USA:"))
            symbolName = "USA:"+symbolName;

        let param = {
            "search" : symbolName.split(':')[1].toUpperCase()
        }

        this._apiService.getStockAutocomplete(param).subscribe(data => {
            if (!this._authService.authCheckApi(data)) return;
            if (data.code==0)
            {
                if (data.result.length > 0)
                {
                    let symbolInfo = {
                        ticker: "USA:" + data.result[0].General_Code,
                        full_name: "USA:" + data.result[0].General_Code,
                        name: data.result[0].General_Code,
                        description: data.result[0].General_Name,
                        type: 'stock',
                        format:sf,
                        session: '0930-1600',
                        timezone: tz,
                        exchange: "USA",
                        listed_exchange: "USA",
                        minmov: 1,
                        pricescale: 100,
                        has_intraday: false,
                        has_no_volume: false,
                        has_weekly_and_monthly: true,
                        supported_resolutions: rs,
                        volume_precision: 2,
                        data_status: "streaming" as "streaming"
                    };
                    onResolve(symbolInfo);
                }
                else
                {

			        onError('cannot resolve symbol');
                }

            }
        });






    }

	public async getBars(
        symbolInfo: TradingView.LibrarySymbolInfo,
        resolution: TradingView.ResolutionString,
        periodParams: TradingView.PeriodParams,
        onResult: TradingView.HistoryCallback,
        onError: TradingView.ErrorCallback
    )
    {

        const { from, to, firstDataRequest,countBack } = periodParams;
        let fdate = processEpochDate(new Date(from*1000))
        let tdate = processEpochDate(new Date(to*1000))

        if (countBack>0)
        {

            fdate = alignCountBack(from,to,resolution.charAt(1),countBack);
        }

        let param = {
            "stock" : symbolInfo.name,
            "period" : resolution.charAt(1).toLowerCase(),
            "from" : fdate,
            "to" :(firstDataRequest)?processEpochDate(new Date()):tdate
        }

        this._apiService.getEOD(param).subscribe(data => {
            if (data.code==0)
            {
                if (data.result.length > 0)
                {
                    let bars:any = [];
                    data.result.forEach(bar => {
                        let btime = new Date(bar.date).getTime();
                        bars = [...bars, {
                            time: btime,
                            low: bar.low,
                            high: bar.high,
                            open: bar.open,
                            close: bar.close,
                            volume: bar.volume
                        }];
                    });

                    onResult(bars, {
                        noData: false,
                    });
                }
                else
                {
                    onResult([], {
                        noData: true,
                    });
                }
            }
            else
            {

                onError('[getBars]: Get error');
            }
        });

    }

	public  subscribeBars(
        symbolInfo: TradingView.LibrarySymbolInfo,
        resolution: TradingView.ResolutionString,
        onTick: TradingView.SubscribeBarsCallback,
        listenerGuid: string,
        onResetCacheNeededCallback: () => void)
    {

    }

	public  unsubscribeBars(
        listenerGuid: string
        )
    {

    }


    // implement TradingView.IDatafeedChartApi interface here


    public  onReady(
        callBack: TradingView.OnReadyCallback
    )
    {

        setTimeout(() => callBack(configurationData));
    }

}
