import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError,EMPTY } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})

/**
 * Class to handle requests
 */
export class RequestHandlersService {

  constructor(
    private storageService: StorageService,
    private toastr: ToastrService,
    private router: Router,
  ) {

  }

  /**
   * Handle http related errors
   * @param error
   */
  handleHttpErrors(error: HttpErrorResponse) {
    console.log("showing error");
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    this.toastr.error(errorMessage, "Error", { timeOut: 15000 });

    /*Swal.fire({
      icon: 'error',
      title: 'Student Portal Error, kindly inform administrator',
      text: `${errorMessage}`,
      footer: `<a href="https://beyondinsightstech.atlassian.net/servicedesk/customer/portals">Snapshot this screen, and click here to notify BI Support</a>`
    });*/

    return throwError(
      'Something bad happened; please try again later.');

  }

  /**
   * Handle response from back-end
   * @param response
   * @param requestedAction
   */
  handleResponse(response: any, requestedAction: string) {
    let errorMessage = '';
    let errorTitle = '';
    let errorArray = [] as any;

    if (response.code !== 0) {
      if (!!response && !!response.errors) {
        for (let key of Object.keys(response.errors)) {
          let errorValue = response.errors[key];
          if (!!Array.isArray(errorValue)) {
            for (var i = 0; i < errorValue.length; i++) {
              errorArray.push(errorValue[i]);
            }
          }
          else {
            errorArray.push(errorValue);
          }
        }
      }

      errorTitle = `Error Code: ${response.code}`;
      if (!!errorArray.length) {
        for (var i = 0; i < errorArray.length; i++) {
          errorMessage += `\n` + errorArray[i];
        }
      }
      else {
        errorMessage = `${response.message}`;
      }

      this.toastr.error(errorMessage, errorTitle, { timeOut: 15000 });

      // If unauthorized response
      /*if (response.code === 4) {
        console.log("unauthorised");
        this.storageService.signOut();
        this.router.navigate(['/login']);
      }*/
    }
    else {
      this.toastr.success(requestedAction + ' successful', 'Success', { timeOut: 5000 });
    }
  }
}
