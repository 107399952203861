import { NgModule } from '@angular/core';

import { MenuItems } from './menu-items/menu-items';
import { StockDetails } from './stock-details/stock-details';
import { StockItems } from './stock-details/stock-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { DateNormalPipe } from './date-normal.pipe';
import {TradingViewEodProvider} from './trading-view-eod-provider'
import {StudentPortalSaveLoad} from './student-portal-save-load'
import {Faqglossary} from './faq-glossary/faq-glossary'


@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    DateNormalPipe
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    DateNormalPipe
   ],
  providers: [ MenuItems,StockDetails,StockItems,TradingViewEodProvider,StudentPortalSaveLoad,Faqglossary ]
})
export class SharedModule { }
