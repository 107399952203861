
<div class="login-background">
    <div class="main-login-section" fxLayout="row wrap" fxLayoutGap="grid">
        <div fxFlex="70%" fxFlex.sm="70%" fxHide.xs>
            <mat-card class="company-info-section"
                style="background: url(assets/images/background/beyondinsightshomepage.jpg) no-repeat; background-size:cover">
                <p>Welcome to</p>
                <h2 class="company-name">Beyond Insights Student Portal</h2>
                <p>
                    <mat-icon class="company-check" [inline]="true">check</mat-icon>Get started
                </p>
                <p>
                    <mat-icon class="company-check" [inline]="true">check</mat-icon>Get consistent
                </p>
                <p>
                    <mat-icon class="company-check" [inline]="true">check</mat-icon>Get results
                </p>
                <p>
                    Educate yourself with the most complete curriculum in stock market investing and
                    the most effective support by a team of experienced coaches.
                    Sign in now to get started!
                </p>
            </mat-card>
        </div>
        <div fxFlex="30%" fxFlex.sm="30%" fxFlex.xs="100%">
            <mat-card class="login-section">
              <!--<div *ngIf="!firstTimeLogin&&!isProd" class="whatsappmessenger">
                  <a href="https://api.whatsapp.com/send/?phone=60374902111" target="_blank">
                    <img src="assets/images/icon_whatsapp.png" class="iconshadow">
                  </a>
                  <a href="http://m.me/beyondinsights" target="_blank" style="color:white;">
                    <img src="assets/images/icon_fb.png" class="iconshadow">
                  </a>
                  <a href="mailto:support@beyondinsights.net" target="_blank" style="color:white;">
                    <img src="assets/images/email.png" class="iconshadow">
                  </a>
              </div>-->
                <img src="assets/images/logo.png" class="light-logo">
                <!--<h3 class="login-header" style="padding-top:0.5rem;">Student Portal</h3>-->

                <!-- Login section -->
                <div *ngIf="!changePasswordRequired() && !resetPassword && !submittedResetPassword" class="login-form">
                    <h3 class="login-header">Login</h3>
                    <mat-card-content>
                        <div>
                            <form (keydown.enter)="login()">
                                <mat-form-field class="full-width-input">
                                    <input matInput placeholder="Email" [formControl]="emailFormControl"
                                        [errorStateMatcher]="matcher" [(ngModel)]="student_email"
                                        [ngModelOptions]="{standalone: true}" type="email" name="student_email"
                                        required>
                                    <mat-error
                                        *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                        Please enter a valid email address
                                    </mat-error>
                                    <mat-error *ngIf="emailFormControl.hasError('required')">
                                        Email is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="full-width-input">
                                    <!--hintLabel="Min 8 alphanumeric characters with at least an upper and lowercase"-->
                                    <input matInput placeholder="Password" [(ngModel)]="password"
                                        (keypress)="keyPressChecker($event)" [type]="hidePassword ? 'password' : 'text'"
                                        name="password" required>
                                    <mat-icon matSuffix (click)="hidePassword = !hidePassword" class="show-hide-pass">
                                        {{hidePassword ? 'visibility_off' : 'visibility'}}
                                    </mat-icon>
                                    <mat-error *ngIf="!password.length">
                                        Please enter your password
                                    </mat-error>
                                </mat-form-field>
                                <!--<div class="password-section">
                                    <mat-checkbox [(checked)]="isChecked" (change)="OnCheckboxChange($event)">
                                        Stay logged in
                                    </mat-checkbox>
                                </div>-->
                                <button mat-raised-button type="submit" class="login-button" (click)="login()"
                                    [disabled]="!student_email.length ||
                                    emailFormControl.hasError('email') && !emailFormControl.hasError('required') || !password.length">
                                    Login
                                </button>

                            </form>

                                <!--<div *ngIf="!firstTimeLogin">
                                    <p>Or</p>
                                    <button class="btn btn-facebook" (click)="loginWithFacebook()">
                                        Login with Facebook
                                    </button>
                                </div>-->
                                <div *ngIf="!firstTimeLogin">
                                  <button class="btn btn-facebook" (click)="signInWithFB()">
                                    <i class="fa fa-facebook"></i>&nbsp; Login with Facebook
                                  </button>

                              </div>
                              <div *ngIf="!firstTimeLogin">
                                <button class="btn btn-google" (click)="signInWithGoogle()">
                                  <i class="fa fa-google"></i>&nbsp; Login with Google
                                </button>
                                </div>
                                <div>
                                  <mat-button class="forgot-password" (click)="forgotPassword(false)">
                                    Forgot Password
                                  </mat-button>
                                  &nbsp;|&nbsp;
                                  <mat-button class="forgot-password" (click)="open(content)">
                                    Login Guide
                                  </mat-button>
                              </div>
                              <div>
                                <mat-button class="forgot-password" (click)="forgotPassword(true)">
                                  First Time Login
                                </mat-button>
                              </div>


                        </div>
                        <div class="footer">
                            <p>
                                By logging in, you agree to our
                                <a href="https://www.beyondinsights.net/terms-of-service/">Terms of Use</a>
                                and acknowledge that you read our Privacy Policy
                            </p>
                        </div>
                    </mat-card-content>
                </div>

                <div *ngIf="!!resetPassword" class="login-form">
                    <h3 class="login-header" *ngIf="!isFirstTimeLogin">Forgot Password</h3>
                    <h3 class="login-header" *ngIf="isFirstTimeLogin">First Time Login</h3>
                    <mat-card-content>
                        <form (keydown.enter)="submitResetPasswordRequest(email)">
                            <mat-form-field class="full-width-input">
                                <input matInput placeholder="Email" [(ngModel)]="email" name="email" required>
                                <mat-error *ngIf="!email.length&&!isFirstTimeLogin">
                                    Please enter your email to reset password
                                </mat-error>
                                <mat-error *ngIf="!email.length&&isFirstTimeLogin">
                                  Please enter your email to receive your first time login email
                              </mat-error>
                                <mat-error *ngIf="!!invalidEmail">
                                    Please enter a valid registered email
                                </mat-error>
                            </mat-form-field>

                            <button mat-raised-button type="submit" class="login-button" [disabled]="resetButtonDisable"
                                (click)="submitResetPasswordRequest(email)" color="primary" [disabled]="!email.length">
                                Submit
                            </button>
                        </form>
                        <div class="footer">
                            <p>
                                By logging in, you agree to our
                                <a href="https://www.beyondinsights.net/terms-of-service/">Terms of Use</a>
                                and acknowledge that you read our Privacy Policy
                            </p>
                        </div>
                    </mat-card-content>
                </div>

                <div *ngIf="!!submittedResetPassword" class="login-form">
                    <h3 class="login-header" *ngIf="!isFirstTimeLogin">Forgot Password</h3>
                    <h3 class="login-header" *ngIf="isFirstTimeLogin">First Time Login</h3>
                    <!--<h3 class="login-header">Reset Password</h3>-->
                    <mat-card-content>
                        <p *ngIf="!isFirstTimeLogin">
                            If a matching account was found, we have sent you a link to a page in your email,
                            to allow you to reset your password.
                        </p>
                        <p *ngIf="isFirstTimeLogin">
                          If a matching account was found, we have sent you first time login email,
                          to allow you to log in to your portal.
                      </p>

                        <p>
                            Click
                            <mat-button  class="forgot-password" [disabled]="resetButtonDisable" (click)="resendResetPasswordRequest(email)">
                                here
                            </mat-button>
                            to resend the email if you didn't receive it.
                        </p>
                        <p>
                            Click
                            <mat-button class="forgot-password" (click)="backtoLogin()">
                                here
                            </mat-button>
                            to go back to login.
                        </p>
                        <div class="footer">
                            <p>
                                By logging in, you agree to our
                                <a href="https://www.beyondinsights.net/terms-of-service/">Terms of Use</a>
                                and acknowledge that you read our Privacy Policy
                            </p>
                        </div>
                    </mat-card-content>
                </div>

                <!-- Change password section-->
                <div *ngIf="!!changePasswordRequired()" class="login-form">
                    <h3>Change Password</h3>
                    <mat-card-content>
                        <mat-card-subtitle>
                            As this is your first login, you are required to change password before proceeding
                        </mat-card-subtitle>
                        <form (keydown.enter)="changePassword()">
                            <mat-form-field class="full-width-input"
                                hintLabel="Min 8 alphanumeric characters with at least an upper and lowercase">
                                <input matInput placeholder="New Password" [(ngModel)]="new_password"
                                    (keypress)="keyPressChecker($event)" [type]="hideNewPassword ? 'password' : 'text'"
                                    name="new_password" minlength="8" required>
                                <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword" class="show-hide-pass">
                                    {{hideNewPassword ? 'visibility_off' : 'visibility'}}
                                </mat-icon>
                                <mat-error>
                                    Please enter your new password
                                </mat-error>
                                <mat-error *ngIf="!!new_password.length && new_password.length < 8">
                                    Password needs to be 8 characters long
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="full-width-input"
                                hintLabel="Min 8 alphanumeric characters with at least an upper and lowercase">
                                <input matInput placeholder="Confirm Password" [(ngModel)]="password_confirmation"
                                    (keypress)="keyPressChecker($event)"
                                    [type]="hideConfirmPassword ? 'password' : 'text'" name="password_confirmation"
                                    minlength="8" required>
                                <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
                                    class="show-hide-pass">
                                    {{hideConfirmPassword ? 'visibility_off' : 'visibility'}}
                                </mat-icon>
                                <mat-error>
                                    Please confirm your new password
                                </mat-error>
                                <mat-error *ngIf="!!password_confirmation.length && password_confirmation.length < 8">
                                    Password needs to be 8 characters long
                                </mat-error>
                            </mat-form-field>
                            <button mat-raised-button class="login-button" type="submit" (click)="changePassword()" color="primary"
                                [disabled]="!new_password.length || !password_confirmation.length ||
                                    (!!new_password.length && new_password.length < 8) ||
                                    (!!password_confirmation.length && password_confirmation.length < 8)">
                                Change Password
                            </button>
                        </form>
                        <div class="footer">
                            <p>
                                By logging in, you agree to our
                                <a href="https://www.beyondinsights.net/terms-of-service/">Terms of Use</a>
                                and acknowledge that you read our Privacy Policy
                            </p>
                        </div>
                    </mat-card-content>
                </div>
            </mat-card>
        </div>
    </div>
</div>
