import { Injectable } from '@angular/core';

const TOKEN_KEY = 'token';
const USER_KEY = 'userId';
const FB_TOKEN_KEY = 'fbtoken';

@Injectable({
  providedIn: 'root'
})

/**
 * Class to handle browser side storage
 */
export class StorageService {

  constructor() { }

  //sessionStorage
  // Clear local storage on sign out
  signOut(): void {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  // Save authorization token
  public saveToken(token: string, rememberFlag: boolean): void {
    window.localStorage.removeItem(TOKEN_KEY);  
    if (!!rememberFlag) {
      window.localStorage.setItem(TOKEN_KEY, token);
    }
    else {
      window.localStorage.setItem(TOKEN_KEY, token);
    }
  }

  public saveFBToken(token: string, rememberFlag: boolean): void {
    window.localStorage.removeItem(FB_TOKEN_KEY);      
    if (!!rememberFlag) {
      window.localStorage.setItem(FB_TOKEN_KEY, token);
    }
    else {
      window.localStorage.setItem(FB_TOKEN_KEY, token);
    }    
  }

  // Get the authorization token
  public getFBToken(): any {
    return localStorage.getItem(FB_TOKEN_KEY);
  }

  // Get the authorization token
  public getToken(): any {
    return localStorage.getItem(TOKEN_KEY);
  }

  // Save a flag to be used to check in other places
  public saveFlag(flagValue: string, flagName: string): void {
    window.localStorage.setItem(flagName, flagValue);
  }
  
  // Get a flag to check
  public getFlag(flagName: string) {
    return localStorage.getItem(flagName);
  }

  // Remove a flag no longer in use
  public removeFlag(flagName: string): void {
    window.localStorage.removeItem(flagName);
  }

  // Save the logged in user id
  public saveUser(user, rememberFlag: boolean): void {
    window.localStorage.removeItem(USER_KEY);
    if (!!rememberFlag) {
      window.localStorage.setItem(USER_KEY, user);
    }
    else {
      window.localStorage.setItem(USER_KEY, user);
    }
  }

  // Get the logged in user
  public getUser(): any {
    return localStorage.getItem(USER_KEY);
  }

  // Save the logged in student name
  public saveStudentName(studentName, rememberFlag: boolean): void {
    window.localStorage.removeItem('studentName');
    if (!!rememberFlag) {
      window.localStorage.setItem('studentName', studentName);
    }
    else {
      window.localStorage.setItem('studentName', studentName);
    }
  }

  // Get the logged in student name
  public getStudentName(): any {
    return window.localStorage.getItem('studentName');
  }

  // Save the logged in student email
  public saveStudentEmail(studentEmail, rememberFlag: boolean): void {
    window.localStorage.removeItem('studentEmail');
    if (!!rememberFlag) {
      window.localStorage.setItem('studentEmail', studentEmail);
    }
    else {
      window.localStorage.setItem('studentEmail', studentEmail);
    }
  }

  // Get the logged in student email
  public getStudentEmail(): any {
    return window.localStorage.getItem('studentEmail');
  }

  // Save the current workshop data
  public saveWorkshopData(workshopData): void {
    window.localStorage.setItem('workshopName', workshopData.program + ' ' + '[' + workshopData.location_code + ']' + ' ' +
      workshopData.workshop_start + ' to ' + workshopData.workshop_end);
    window.localStorage.setItem('workshopId', workshopData.workshop_id);
  }

  // Get current workshop name
  public getWorkshopName(): any {
    return localStorage.getItem('workshopName');
  }

  // Get current workshop id
  public getWorkshopId(): any {
    return localStorage.getItem('workshopId');
  }
}