import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';
import {BILocation} from '../shared/event-constants';
import { environment } from '../../environments/environment';

import { SocialAuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {OnboardPopupComponent} from '../components/onboard-popup/onboard-popup.component';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';

// import { ComponentCanDeactivate } from '../helpers/navigation.guard';
// import { HostListener } from '@angular/core';
// import { Observable } from 'rxjs/Observable';

import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

/**
 * Class for login functions
 */
export class LoginComponent implements OnInit {

  static isIncognito:boolean = false;
  user: SocialUser;
  isProd: boolean;
  closeResult = '';
    // @HostListener allows us to also guard against browser refresh, close, etc.
    // @HostListener('window:beforeunload')
    // canDeactivate(): Observable<boolean> | boolean {
    //   // insert logic to check if there are pending changes here;
    //   // returning true will navigate without confirmation
    //   if (!!this.changePasswordRequired) {
    //     this.toastr.info('You need to change your password before proceeding', 'Redirected');
    //     this.route.navigate(['/login']);
    //     return false;
    //   }
    //   else {
    //     return true;
    //   }
    //   // returning false will show a confirm dialog before navigating away
    // }

  constructor(
    private authService: AuthService,
    public storageService: StorageService,
    private toastr: ToastrService,
    public activatedRoute: ActivatedRoute,
    public route: Router,
    private socialAuthService:  SocialAuthService,
    private modalService: NgbModal
  ) {
    this.isProd = environment.production;
   }

  // Validator for the form control
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();

  // Flag to determine to show first time login or login with Facebook option
  firstTimeLogin: boolean = false;

  // Initializing variables for login fields
  student_email = '';
  password = '';
  hidePassword: boolean = true;
  hideNewPassword: boolean = true;
  hideConfirmPassword: boolean = true;

  // Initializing variables for password check
  noLowercase: boolean = false;
  noUppercase: boolean = false;
  noDigits: boolean = false;

  // Reset password variable initialization
  email = '';

  // Set remember password flag to false by default
  isChecked: boolean = false;
  resetPassword: boolean = false;
  submittedResetPassword: boolean = false;
  invalidEmail: boolean = false;
  resetButtonDisable:boolean = false;

  // Initializing variables for change password fields
  static changePasswordRequired: boolean;
  new_password = '';
  password_confirmation = '';
  user_id = '';
  old_password = '';

  isFirstTimeLogin: boolean = false;

  // Function to handle key down event for enter key
  handleKeyDown(e) {
    // Keycode for enter key
    if (e.keyCode === 13) {
      this.login();
    }
  }

  /**
   * Function to check allowed keypresses and prevent unallowed keypresses
   * @param event
   */
  keyPressChecker(event) {
    var input = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9~`!@#$%^&*()-_=+{},.<>/?]/.test(input)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  /**
   * Function to check password format and return message on UI
   * @param passwordString
   */
  checkPasswordFormat(passwordString) {
    if (passwordString.length > 7) {
      if (!/[a-z]/.test(passwordString)) {
        this.noLowercase = true;
      }
      else {
        this.noLowercase = false;
      }
      if (!/[A-Z]/.test(passwordString)) {
        this.noUppercase = true;
      }
      else {
        this.noUppercase = false;
      }
      if (!/[0-9]/.test(passwordString)) {
        this.noDigits = true;
      }
      else {
        this.noDigits = false;
      }

      return (!!this.noLowercase || !!this.noUppercase || !!this.noDigits);
    }
  }

  open(content) {
    const modalRef = this.modalService.open(OnboardPopupComponent, {ariaLabelledBy: 'modal-basic-title', size: 'xl'});
    //modalRef.componentInstance.url = 'https://www.youtube.com/embed/dEUhzI9Z8-c';
    modalRef.componentInstance.title = 'Login Guide';
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  /**
   * Function to generate the password validation message in a swal
   */
  generatePasswordValidationMessage() {
    var passwordValidationMessage = '';
    if (!!this.noLowercase) {
      passwordValidationMessage += '<p>Password needs at least a lowercase character.</p>';
    }
    if (!!this.noUppercase) {
      passwordValidationMessage += '<p>Password needs at least an uppercase character.</p>';
    }
    if (!!this.noDigits) {
      passwordValidationMessage += '<p>Password needs at least a number.</p>';
    }

    Swal.fire({
      title: 'Invalid password format',
      html: passwordValidationMessage,
      icon: 'warning',
      confirmButtonText: 'Okay',
      heightAuto: false
    });
  }

  /**
   * Assigns checked value on checkbox change
   * @param event
   */
  OnCheckboxChange(event): void {
    this.isChecked = event.checked;
  }

  /**
   * Function for login. Calls authservice login method
   */
  login() {
    var credentials = {
      email: this.student_email,
      password: this.password
    }
    // Call auth service login
    let loginPassChange = this.authService.login(credentials, this.isChecked);
    console.log("Login Pass Change");
    console.log(loginPassChange);
  }

  /**
   * Facebook Login
   */
  loginWithFacebook() {
    this.authService.facebookLogin();
  }

  logoutFB() {
    this.authService.FBLogout();
  }

  backtoLogin() {
    this.submittedResetPassword = false;
    this.resetPassword = false;
    //this.route.navigate([BILocation.Home]);
  }

  /**
   * Function to toggle forgot password form
   */
  forgotPassword(x) {
    this.isFirstTimeLogin = x;
    this.resetPassword = true;
  }

  /**
   * Function to submit reset password request
   */
  submitResetPasswordRequest(email: string) {
    this.resetButtonDisable = true;
    this.authService.forgotPassword(email).subscribe(data => {
      // Email sent successfully
      this.resetButtonDisable = false;
      if (data.code === 0) {
        this.submittedResetPassword = true;
        this.resetPassword = false;
      }
      // Email send failed
      else if (data.sent === 0) {
        this.invalidEmail = true;
      }
    });
  }

  resendResetPasswordRequest(email: string) {
    this.resetButtonDisable = true;
    this.authService.forgotPassword(email).subscribe(data => {
      // Email resent successfully
      this.resetButtonDisable = false;
      if (data.code === 0) {
        Swal.fire({
          title: 'Success',
          text: 'A new email has been sent.',
          icon: 'success',
          confirmButtonText: 'Okay',
          heightAuto: false
        });
      }
    });
  }

  /**
   * Function for change password. Calls authservice changePassword method
   */
  changePassword() {

    // If password format does not contain any lowercase/uppercase/digits
    if (!!this.checkPasswordFormat(this.new_password) || !!this.checkPasswordFormat(this.password_confirmation)) {
      this.generatePasswordValidationMessage();
    }
    else {
      var credentials = {
        old_password: this.old_password,
        password: this.new_password,
        password_confirmation: this.password_confirmation,
      }
      // Call auth service change password
      this.authService.changePassword(credentials);
    }
  }


  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
      //console.log('success', x)
    }, err => {
      console.log('rejected', err);
      if(LoginComponent.isIncognito)
      {
        this.toastr.error("Google Login requires Cookies, which won't work in Incognito Mode. Please use without Incognito mode if you need to login with Google", "Google Login Warning", { timeOut: 60000, positionClass:'toast-top-center' });
      }
    });
  }

  signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.socialAuthService.signOut();
  }

  refreshGoogleToken(): void {
    this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }

  ngOnInit() {

  }

  changePasswordRequired() {
    return LoginComponent.changePasswordRequired;
  }

  /**
   * On component initialization, if first time login, get the data from authService
   */
  ngAfterViewInit() {
    console.log("loading after view");
    this.socialAuthService.authState
    .subscribe(user => {
      console.log(user);
      if (user!=null)
      {
        if (user.provider=="GOOGLE")
          this.authService.login(user, this.isChecked,"google");
        else if(user.provider=="FACEBOOK")
          this.authService.login(user, this.isChecked,"facebook");
      }
      //this.user = user;
    });
    // If user is logged in
    if (!!this.authService.isLoggedIn()) {
      this.route.navigate([BILocation.Home]);
    }
    else
    {
      this.authService.firstTimeLogin.subscribe(data => {
        var loginData: any = data;
        console.log(loginData);
        // Setting the data obtained from service into the local variables
        this.user_id = !!loginData.user_id ? loginData.user_id : '';
        this.old_password = !!loginData.old_password ? loginData.old_password : '';
        LoginComponent.changePasswordRequired = loginData.required_password_change;
      }
      );
    }
  }

}
