import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../services/storage.service';

@Injectable({ providedIn: 'root' })

/**
 * Class PasswordChangedGuard is responsible for checking if user has changed password
 */
export class PasswordChangedGuard implements CanActivate {
    constructor(
        private router: Router,        
        private storageService: StorageService,
        private toastr: ToastrService
    ) { }

    /**
     * Checks if a user can activate the route
     * @param route 
     * @param state 
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!!this.storageService.getFlag('requireChangePassword')) {
            this.storageService.signOut();
            this.toastr.info('You need to change your password before proceeding', 'Redirected');
            this.router.navigate(['/login']);
            return false;
        }
        // If user is logged in
        else {
            return true;
        }
    }
}