import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { retry, catchError,take } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { RequestHandlersService } from './request-handlers.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

import { BehaviorSubject, Observable, from, of, EMPTY } from 'rxjs';
import { map, concatMap, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import {BILocation} from '../shared/event-constants';

import { SocialAuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';

import {LoginComponent} from '../login/login.component';

const endpoint: string = environment.apiUrl;
const nodeEndpoint: string = (environment.nodeapiUrl!='')?environment.nodeapiUrl:endpoint;

declare const FB: any;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

/**
 * Class to handle all authorization related services
 */
export class AuthService {

  public redirectUrl: any;

  constructor(
    private http: HttpClient,
    public storageService: StorageService,
    private requestHandler: RequestHandlersService,
    private _ngZone: NgZone,
    private _router: Router,
    private toastr: ToastrService,
    private datepipe: DatePipe,
    private socialAuthService:  SocialAuthService
    ) { }

  // Function to run ngzone and navigate for tasks outside of angular
  goTo(route) {
    this._ngZone.run(() => {
      this._router.navigate([route])
    });
  }

  // Behaviour subject as observable to pass login data
  private firstloginFlag = new BehaviorSubject({});
  firstTimeLogin = this.firstloginFlag.asObservable();

  private storeDetails(data, credentials, rememberFlag)
  {
      // Save access token to be used for changing password api
      this.storageService.saveToken(data.result.access_token, rememberFlag);
      this.storageService.saveUser(data.result.user_id, rememberFlag);
      this.storageService.saveStudentEmail(credentials.email, rememberFlag);
      this.storageService.saveStudentName(data.result.name, rememberFlag);

  }

  /**
   * Function to login into student portal
   * @param credentials
   * @param rememberFlag Flag to determine user stay signed in
   */
  public login(credentials, rememberFlag, isSocial:string="none") {
    this.storageService.signOut();
    //this.socialAuthService.signOut(true);

    let sw = Swal.fire({
      icon: 'info',
      title: 'Logging into Student Portal',
      showConfirmButton: false,
      timer: 20000,
      heightAuto: false
    })

    var url = endpoint + 'login';
    var param:any =  {
      email: credentials.email,
      password: credentials.password
    }

    if (isSocial == "facebook") {
      url = endpoint + 'fb_login';
      param =  {
        auth: credentials.authToken,
      }
    }
    else if (isSocial == "google") {
      url = endpoint + 'google_login';
      param =  {
        auth: credentials.idToken,
      }
    }

    this.http.post<any>(url, param, httpOptions)
    .pipe(catchError(this.requestHandler.handleHttpErrors))
    .subscribe(data => {
      Swal.close();
      if (!!data.result && !!data.result.required_verify_profile)
        this.storageService.saveFlag(data.result.required_verify_profile, 'verifyProfile');

      if (!!data.result && !!data.result.required_password_change) {
        console.log("going into password change");
        var loginData = {
          old_password: credentials.password,
          required_password_change: data.result.required_password_change
        }

        this.firstloginFlag.next(loginData);
        this.storeDetails(data, credentials, rememberFlag);
        this.storageService.saveFlag(data.result.required_password_change, 'requireChangePassword');
        LoginComponent.changePasswordRequired = true;
        //this._router.navigate(['/login']);
        return true;
      }
      else
        this.requestHandler.handleResponse(data, 'Login');

      if (data.code === 0) {

        this.storeDetails(data, credentials, rememberFlag);
        if (data.result.required_verify_profile)
        {
          this._router.navigateByUrl('/first-login-wizard');
          return;
        }
        if (this.redirectUrl) {
          this._router.navigate([this.redirectUrl]);
          this.redirectUrl = null;
        }
        else
          this._router.navigateByUrl(BILocation.Home);
      }
    });
  }

  /**
   * Facebook side login
   */
  public facebookLogin() {
    FB.login((response: any) => {
      if (response.status === 'connected') {
        console.log(response);
        // If Facebook is accessed via login page
        if(this._router.url === '/login')
          this.facebookLoginAPI(response.authResponse);
        else
          this.connectToFacebookApi(response.authResponse);
      }
      else {
        // The person is not logged into your webpage or we are unable to tell.
        Swal.fire({
          title: 'An unknown error has occured',
          text: ' Unable to login with Facebook at this moment',
          icon: 'error',
          confirmButtonText: 'Okay',
          heightAuto: false
        });
      }
    });
  }

  public authCheckApi(response) {
    if (response.code === 4) {
      this.storageService.signOut();

      if (this._router.url!="/login")
      /*{
        alert("Error during login, you may have taken too long during password change, please relogin again");
        window.location.reload();
      }
      else*/
        this._router.navigate(['/login']);

      return false;
    }

    return true;

  }

  public toasterClassLink() {
    //console.log("Toaster Link");
  }

  /**
   * Backend api for connecting student to Facebook account
   * @param response
   */
  public connectToFacebookApi(response) {
    this.http.post<any>(endpoint + 'personal/attach_facebook_id', {
      facebook_user_id: response.userID,
    }, httpOptions)
    .pipe(catchError(this.requestHandler.handleHttpErrors))
    .subscribe(data => {
      if (data.code === 0) {
        Swal.fire({
          title: 'Success',
          text: 'Successfully connected Facebook account.',
          icon: 'success',
          confirmButtonText: 'Okay',
          heightAuto: false
        });
      }
    });
  }

  /**
   * Backend api for disconnecting student from Facebook accounte
   */
  public disconnectFromFacebookApi() {
    this.http.post<any>(endpoint + 'personal/detach_facebook_id', {
    }, httpOptions)
    .pipe(catchError(this.requestHandler.handleHttpErrors))
    .subscribe(data => {
      this.FBLogout();
      Swal.fire({
        title: 'Success',
        text: 'Successfully disconnected Facebook account.',
        icon: 'success',
        confirmButtonText: 'Okay',
        heightAuto: false
      });
    });
  }

  /**
   * Backend API to login with a connected Facebook account
   * @param response
   */
  public facebookLoginAPI(response: any) {

    var newDataAccessExpiration = new Date(response.data_access_expiration_time * 1000);
    var convertedDataAccessExpiration = this.datepipe.transform(newDataAccessExpiration, 'yyyy-MM-dd HH:mm:ss');

    this.http.post<any>(endpoint + 'facebook_login', {
      facebook_user_id: response.userID,
      facebook_access_token: response.accessToken,
      facebook_signed_request: response.signedRequest,
      facebook_expires_in: response.expiresIn,
      facebook_data_access_expiration_time: convertedDataAccessExpiration
    }, httpOptions)
      .pipe(catchError(this.requestHandler.handleHttpErrors))
      .subscribe(data => {
        // If login success
        if (data.code === 0) {
          this.storageService.saveToken(data.result.access_token, true);
          this.storageService.saveUser(data.result.user_id, true);
          this.storageService.saveStudentName(data.result.name, true);
          this.storageService.saveFBToken(response.accessToken, true);
          this.goTo(BILocation.Home);
        }
        else {
          Swal.fire({
            title: 'Error',
            text: 'This account has not connected to our student portal yet.',
            icon: 'error',
            confirmButtonText: 'Okay',
            heightAuto: false
          });
          this.FBLogout();
        }
      });
  }

  public FBLogout() {
    FB.api('/me/permissions', 'delete', null, () => FB.logout());
  }

  public FBConnectionStatus() {
    return this.http.post<any>(endpoint + 'personal/facebook_connect_status', {
    }, httpOptions)
    .pipe(catchError(this.requestHandler.handleHttpErrors));
  }

  /**
   * Checks if token and user id is in session
   */
  public isLoggedIn() {
    var token = this.storageService.getToken();
    // var user = this.storageService.getUser();
    return !!token;
  }

  /**
   * Function to logout and clear storage
   */
  public logout() {
    this.http.post<any>(endpoint + 'logout', {});
    // Clear storage
    this.storageService.signOut();
    this.socialAuthService.signOut(true);
    this.toastr.success('Logout successful', 'Success', { timeOut: 5000 });
    setTimeout(()=>{ this._router.navigateByUrl('/login'); }, 500);

  }

  /**
   * Function to change password
   * @param credentials
   */
  public changePassword(credentials) {
    return this.http.post<any>(endpoint + 'change_password', {
      old_password: credentials.old_password,
      password: credentials.password,
      password_confirmation: credentials.password_confirmation
    }, httpOptions)
      .pipe(catchError(this.requestHandler.handleHttpErrors))
      .subscribe(data => {

        if (!this.authCheckApi(data))
        {
          return false;
        };

        this.requestHandler.handleResponse(data, 'Change password');
        if (data.code === 0) {
          var loginData = {
            first_time_login: false
          }

          this.firstloginFlag.next(loginData);

          if (!!this.storageService.getFlag('verifyProfile')) {
            this.storageService.removeFlag('requireChangePassword');
            this._router.navigateByUrl('/first-login-wizard');
          }
        }
      });
  }

    /**
   * Function to send reset password email
   * @param credentials
   */
  public forgotPassword(email) {
    return this.http.post<any>(endpoint + 'forgotpasswordrequest', {
      email: email,
    }, httpOptions)
      .pipe(catchError(this.requestHandler.handleHttpErrors));
  }
}
