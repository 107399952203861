<!--<app-coachtable></app-coachtable>-->

<mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>Coach Life Wheel Reflections</mat-card-title>
      <mat-card-subtitle>Reflections</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
          </mat-form-field>
          <button mat-raised-button color="primary" (click)="download()">Download</button>

          <div class="table-responsive">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">


            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="studentid">
              <th mat-header-cell *matHeaderCellDef> Student ID </th>
              <td mat-cell *matCellDef="let element"> {{element.studentid}} </td>
            </ng-container>


            <ng-container matColumnDef="Lifewheel1">
                <th mat-header-cell *matHeaderCellDef> Life Wheel</th>
                <td mat-cell *matCellDef="let element">
                  <div class="containerchart">
                      <canvas  baseChart #wheel1
                      [data]="element.Lifewheel1"
                      [labels]="polarAreaChartLabels"
                      [legend]=true
                      [chartType]="polarAreaChartType"
                      [plugins]="polarAreaPlugins"
                      [options]="options"
                      [colors]="lwcolors"
                      >
                      {{element.studentid}}_1
                    </canvas>
                  </div>
                  <div class="containerchart">
                    <canvas  baseChart #wheel2
                      [data]="element.Lifewheel2"
                      [labels]="polarAreaChartLabels"
                      [legend]=true
                      [chartType]="polarAreaChartType"
                      [plugins]="polarAreaPlugins"
                      [options]="options"
                      [colors]="lwcolors"
                      >
                      {{element.studentid}}_2
                    </canvas>
              </div>
                </td>
              </ng-container>

            <!--<ng-container matColumnDef="Lifewheel2">
                <th mat-header-cell *matHeaderCellDef> Life Wheel 2 </th>
                <td mat-cell *matCellDef="let element">

                </td>
              </ng-container>-->

              <ng-container matColumnDef="updatedate">
                <th mat-header-cell *matHeaderCellDef> Date Updated </th>
                <td mat-cell *matCellDef="let element"> {{element.updatedate}} </td>
              </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
          </table>
        </div>
    </mat-card-content>
  </mat-card>
