import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { timer } from 'rxjs';
import {
  catchError,
  delay,
  retry,
  retryWhen,
  share,
  switchMap,
  takeUntil,
  takeWhile,
} from 'rxjs/operators';

import { environment } from '$environment';

import { RequestHandlersService } from '../../services/request-handlers.service';

import { reportHttpEventProgress } from '../operators';

@Injectable({
  providedIn: 'root',
})
export class IntradayTradingFileUploadService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly requestHandlersService: RequestHandlersService,
  ) {}

  upload(file: File) {
    const formData = new FormData();

    formData.append('file', file);

    return this.httpClient
      .post(
        `${environment.nestApiUrl}intraday-trading-file-uploads/upload`,
        formData,
        {
          observe: 'events',
          reportProgress: true,
        },
      )
      .pipe(
        retry(3),
        reportHttpEventProgress(),
        catchError(this.requestHandlersService.handleHttpErrors),
      );
  }

  findInformation(filename: string) {
    return timer(0, 3000).pipe(
      switchMap(() =>
        this.httpClient.get(
          `${environment.nestApiUrl}intraday-trading-file-uploads/information`,
          {
            params: {
              filename,
            },
          },
        ),
      ),
      retryWhen((errors) => errors.pipe(delay(3000))),
      share(),
      takeWhile((value: any) => value.status === 0, true),
      takeUntil(timer(60000)),
    );
  }
}
