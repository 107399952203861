import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HTTP_INTERCEPTORS, HttpEvent, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { RequestHandlersService } from '../services/request-handlers.service';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import {LoaderService} from '../services/loader.service';

const TOKEN_HEADER_KEY = 'Authorization';
const FB_TOKEN_HEADER_KEY = 'Facebook-Authorization';

@Injectable()

/**
 * Class AuthInterceptor is responsible for functions related to authorization errors
 */
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private storageService: StorageService,
    private _router: Router,
    private toastr: ToastrService,
    private requestHandlersService: RequestHandlersService,
    private auth:AuthService,
    public loader:LoaderService
  ) { }

  /**
   * Handle authorization errors intercepted by the interceptor. Redirects user to login page for 401 or 403
   * @param err
   */
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    this.requestHandlersService.handleHttpErrors(err);
    if (err.status === 401 || err.status === 403) {
      this.storageService.signOut();
      this._router.navigateByUrl(`/login`);
    }
    /*else
    {
      this._router.navigateByUrl(`/`);
      console.log(this._router.url);
    }*/
    return throwError(err);
  }

  /**
   * Function to handle backend errors on a http 200 response
   *
   * Code 1   - Expected Error
   * Code 2   - No Data Found
   * Code 3   - Errors on Parameters
   * Code 4   - Authentication Error
   * Code 5   - Permission Error
   * Code 99  - Unknown Error
   *
   * */
  private handleBackendErrors(httpResponse: any) {
    switch(httpResponse.code) {
      case 1: {
        //this.toastr.error('Code: ' + httpResponse.code + ' - An unexpected error has occured.', 'Error');
        break;
      }
      // Do nothing if code is 2
      case 2: {
        break;
      }
      // Do nothing if code is 3
      case 3: {
        break;
      }
      case 4: {
        this.toastr.info('You need to log in before you can access this page', 'Redirected');
        this.auth.authCheckApi(httpResponse);
        break;
      }
      case 5: {
        this.toastr.error('Code: ' + httpResponse.code + ' - A permission error has occured.', 'Error');
        break;
      }
      case 99: {
        this.toastr.error('Code: ' + httpResponse.code + ' - An unknown error has occured.', 'Error');
        break;
      }
      default: {
        this.toastr.error('Oops, looks like something went wrong.', 'Error');
        break;
      }
    }
  }

  /**
   * Intercepts http requests and sets header with token if token is stored in storage
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.storageService.getToken();
    const fbtoken = this.storageService.getFBToken();
    if (token != null && fbtoken==null) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token)});
    }
    if (token != null && fbtoken != null) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token).set(FB_TOKEN_HEADER_KEY, fbtoken) });
    }
    this.loader.show();
    return next.handle(authReq).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          this.loader.hide();
          // Handle backend errors for non code 0
          if (evt.body.code !== 0) {
            this.handleBackendErrors(evt.body);
          }
        }
      }), catchError(x => this.handleAuthError(x)));
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
