import { Color } from 'ng2-charts';
import { environment } from '../../environments/environment';

export class EventConstants {
    public static EventAttendFail: number = 0;
    public static EventAttend: number = 1;
    public static EventComplete: number = 2;
    public static EventCurrent: number = 3;
    public static EventAttendComplete: number = 4;

}

export class BILocation {
    public static Home:string = (environment.production)?"events":"events";
}

export class EOD {
  public static APIKey:string="613ae3fd6f2296.59790409"
}

export const QCardExpiryDays = 365;
export const QCardExpiryDesc = "1 year";

export const lifeWheelColors=[
    {
      backgroundColor: [
        'rgba(0, 116, 217, 1)',
        'rgba(240, 18, 190, 1)',
        'rgba(197,118,246 , 1)',
        'rgba(1, 255, 112, 1)',
        'rgba(255, 99, 132,1)',
        'rgba(75, 192, 192,1)',
        'rgba(255, 205, 86,1)',
        'rgba(214, 201, 201, 1)'
        ]
    }
  ];

  export const productimageloader = [
    {code:"MTB",name:"MTB.jpg"},
    {code:"DIT",name:"DIT.png"},
    {code:"GI1",name:"GI1.png"},
    {code:"GI2",name:"GI2.png"},
    {code:"GIL1",name:"GI1.png"},
    {code:"GIL2",name:"GI2.png"},
    {code:"GSME",name:"GSME.png"},
    {code:"II",name:"II.png"},
    {code:"MI",name:"MI.png"},
    {code:"Options",name:"POP.png"},
    {code:"SO",name:"SO.png"},
    {code:"ST",name:"ST.png"},
    {code:"TT",name:"TT.png"},
  ]


export const linesData = [
  {
    name:"Support",
    default:85,
    fieldname:"support",
    overrides: {
      linecolor:"#4caf51",
      linewidth:4,
      linestyle:1,
      showLabel:true,
      horzLabelsAlign:"right",
      textcolor:"#4caf51",
    }
  },
  {
    name:"Resistance",
    default:125,
    fieldname:"resistance",
    overrides: {
      linecolor:"#f23545",    
      linewidth:4,
      linestyle:1,
      showLabel:true,
      horzLabelsAlign:"right",
      textcolor:"#f23545",
    }

  },
  {
    name:"Stop Loss",
    default:75,
    fieldname:"stop_loss",
    overrides: {
      linecolor:"#f23545",
      linewidth:4,
      linestyle:0,
      showLabel:true,
      horzLabelsAlign:"right",
      textcolor:"#f23545",
    }
    
  },
  {
    name:"Entry Price",
    fieldname:"entry_price",
    default:86.9,
    overrides: {
      linecolor:"#2862ff",
      linewidth:4,
      linestyle:0,
      showLabel:true,
      horzLabelsAlign:"right",
      textcolor:"#2862ff",
    }
  },
  {
    name:"Profit Target",
    fieldname:"profit_target",
    default:124.7,
    overrides: {
      linecolor:"#4caf51",
      linewidth:4,
      linestyle:0,
      showLabel:true,
      horzLabelsAlign:"right",
      textcolor:"#4caf51",
    }
    
  },
]

export const symbolIndicator = [
  {
    "id":"support",
    "shape":"horizontal_line",
    "horzLabelsAlign":"right",
    "text":"support"
  },
  {
    "id":"resistance",
    "shape":"horizontal_line",
    "horzLabelsAlign":"right",
    "text":"resistance"
  },
  {
    "id":"direction_id",
    "value":"long",
    "shape":"long_position",
    "stopLevel":"stop_loss",
    "profitLevel":"profit_target",
    "style":"style_id",
    "price":"entry_price"
  },
  {
    "id":"direction_id",
    "value":"short",
    "shape":"short_position",
    "stopLevel":"stop_loss",
    "profitLevel":"profit_target",
    "style":"style_id",
    "price":"entry_price"
  }  
]
