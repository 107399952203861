    <mat-nav-list appAccordion >
        <mat-list-item class="menu-item" appAccordionLink *ngFor="let menuitem of menuItems.getMenuitem()" routerLinkActive="selected"
            group="{{menuitem.state}}">
            <a class="sidebar menu-item" appAccordion [routerLink]="'/'+menuitem.state" *ngIf="menuitem.type === 'link'">
                <div *ngIf="menuitem.beta==true" class="badge badge-pill badge-warning beta">beta</div>
                <mat-icon>{{ menuitem.icon }}</mat-icon>
                <span>{{ menuitem.name }}</span>
                <span fxFlex></span>
                <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            </a>
            <a class="sidebar menu-item" appAccordion (click)="open(menuitem)" *ngIf="menuitem.type === 'help'">
              <div *ngIf="menuitem.beta==true" class="badge badge-pill badge-warning beta">beta</div>
              <mat-icon>{{ menuitem.icon }}</mat-icon>
              <span>{{ menuitem.name }}</span>
              <span fxFlex></span>
              <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          </a>
            <mat-accordion *ngIf="menuitem.type === 'accordion'">
                <mat-expansion-panel [expanded]="getOpen(menuitem.name)" (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                    class="sidebar-accordion mat-elevation-z0" style="padding:0;" (click)="setOpen(menuitem.name,panelOpenState)">
                    <mat-expansion-panel-header class="sidebar accordion expansionheight">
                      <div *ngIf="menuitem.beta==true" class="badge badge-pill badge-warning beta">beta</div>
                        <mat-panel-title style="align-items: center;">
                            <mat-icon class="accordion-icon white-text" style="margin-right:20px;">{{ menuitem.icon }}</mat-icon>
                            <span class="white-text">{{ menuitem.name }}</span>
                            <span fxFlex></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-list class="sub-category" *ngFor="let submenuitem of menuitem.subMenuitems" style="padding:0;">
                        <mat-list-item class="white-text" style="font-size:13px;" *ngIf="submenuitem.type === 'link'">
                            <a class="sidebar" [routerLink]="'/'+submenuitem.state">
                                <div *ngIf="submenuitem.beta==true" class="badge badge-pill badge-warning beta">beta</div>
                                <mat-icon class="accordion-icon white-text">
                                  {{ submenuitem.icon }}

                                </mat-icon>
                                <span>{{ submenuitem.name }}</span>
                                <span fxFlex></span>
                            </a>
                        </mat-list-item>

                        <mat-list-item class="white-text" style="font-size:13px;" *ngIf="submenuitem.type === 'help'">
                          <a class="sidebar" (click)="open(submenuitem)">
                              <div *ngIf="submenuitem.beta==true" class="badge badge-pill badge-warning beta">beta</div>
                              <mat-icon class="accordion-icon white-text">
                                {{ submenuitem.icon }}

                              </mat-icon>
                              <span>{{ submenuitem.name }}</span>
                              <span fxFlex></span>
                          </a>
                      </mat-list-item>
                    </mat-list>
                </mat-expansion-panel>
            </mat-accordion>

        </mat-list-item>

    </mat-nav-list>
