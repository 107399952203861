import { HttpEventType } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { FileSelectionOptions, IntradayTradingFile } from '../../interfaces';
import { IntradayTradingFileUploadService } from '../../services';

@Component({
  selector: 'app-intraday-trading-file-upload-popup',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './intraday-trading-file-upload-popup.component.html',
  styleUrls: ['./intraday-trading-file-upload-popup.component.scss'],
})
export class IntradayTradingFileUploadPopupComponent implements OnDestroy {
  currentStep:
    | 'Pending file selection'
    | 'Pending file upload'
    | 'Uploading file'
    | 'Pending file information polling'
    | 'Polling file information'
    | 'File information polled' = 'Pending file selection';

  classMap: { [status: string]: string } = {
    PENDING: 'badge-secondary',
    UPLOADING: 'badge-warning',
    'UPLOAD FAILED': 'badge-danger',
    UPLOADED: 'badge-success',
    'IMPORT FAILED': 'badge-danger',
    IMPORTED: 'badge-success',
  };

  fileSelectionOptions: FileSelectionOptions = {
    maximumFileSize: 10 * 1024 * 1024,
    disallowedFilenameCharacters: '%_',
    fileExtensions: ['.xlsm'],
  };

  intradayTradingFile: IntradayTradingFile;

  private fileInformationPollingSubscription$: Subscription;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly intradayTradingFileUploadService: IntradayTradingFileUploadService,
    private readonly activeModal: NgbActiveModal,
    private readonly toastrService: ToastrService,
  ) {}

  ngOnDestroy() {
    if (this.fileInformationPollingSubscription$) {
      this.fileInformationPollingSubscription$.unsubscribe();
    }
  }

  onAcceptFile(file: File) {
    this.intradayTradingFile = {
      file,
      status: 'PENDING',
      uploadProgressPercentage: 0,
      message: '',
    };

    this.currentStep = 'Pending file upload';
  }

  onRejectFile(message: string) {
    this.toastrService.error(message);
  }

  onImportFile() {
    this.intradayTradingFile = {
      ...this.intradayTradingFile,
      ...{
        message: '',
      },
    };

    switch (this.intradayTradingFile.status) {
      case 'PENDING':
      case 'UPLOAD FAILED':
        this.uploadFile();
        break;

      case 'UPLOADED':
      case 'IMPORT FAILED':
        this.pollFileImportStatus();
        break;

      default:
        break;
    }
  }

  protected uploadFile() {
    this.currentStep = 'Uploading file';

    this.intradayTradingFileUploadService
      .upload(this.intradayTradingFile.file)
      .subscribe(
        (httpEventProgress: any) => {
          switch (httpEventProgress.httpEventType) {
            case HttpEventType.Sent:
            case HttpEventType.UploadProgress:
              this.intradayTradingFile = {
                ...this.intradayTradingFile,
                ...{
                  status: 'UPLOADING',
                  uploadProgressPercentage:
                    httpEventProgress.uploadProgress.percentage,
                },
              };

              this.changeDetectorRef.markForCheck();
              break;

            case HttpEventType.Response:
              this.intradayTradingFile = {
                ...this.intradayTradingFile,
                ...{
                  status: 'UPLOADED',
                  uploadProgressPercentage: 100,
                  uniqueFilename:
                    httpEventProgress.response.body?.uniqueFilename,
                },
              };

              this.changeDetectorRef.markForCheck();

              this.pollFileImportStatus();
              break;

            default:
              break;
          }
        },
        (error) => {
          this.intradayTradingFile = {
            ...this.intradayTradingFile,
            ...{
              status: 'UPLOAD FAILED',
              uploadProgressPercentage: 0,
              message: 'Please contact BI support.',
            },
          };

          this.currentStep = 'Pending file upload';

          this.changeDetectorRef.markForCheck();
        },
      );
  }

  protected pollFileImportStatus() {
    this.currentStep = 'Polling file information';

    this.fileInformationPollingSubscription$ =
      this.intradayTradingFileUploadService
        .findInformation(this.intradayTradingFile.uniqueFilename!)
        .subscribe(
          (value: any) => {
            console.log(
              `File information received at ${new Date().toLocaleTimeString()}.`,
            );

            if (value.status !== 0) {
              this.intradayTradingFile = {
                ...this.intradayTradingFile,
                ...{
                  status: value.status === 1 ? 'IMPORTED' : 'IMPORT FAILED',
                  message:
                    value.status === 1
                      ? 'Your uploaded file is imported.'
                      : // Display generic error message
                        // value.error_msg,
                        'File import failed. Please check your email for more information.',
                },
              };

              this.currentStep = 'File information polled';

              this.changeDetectorRef.markForCheck();
            }
          },
          (_) => {},
          () => {
            if (this.currentStep !== 'File information polled') {
              this.intradayTradingFile = {
                ...this.intradayTradingFile,
                ...{
                  message:
                    'Processing is taking longer than expected. We will email you once the uploaded file is processed.',
                },
              };

              this.currentStep = 'Pending file information polling';

              this.changeDetectorRef.markForCheck();
            }
          },
        );
  }

  onClose() {
    this.activeModal.close();
  }
}
