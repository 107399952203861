<div class="modal-header mat-header-layout modal__header">
  <div class="modal-title">Import file</div>

  <button
    class="close"
    [disabled]="
      currentStep === 'Uploading file' ||
      currentStep === 'Polling file information'
    "
    type="button"
    [attr.aria-label]="
      currentStep === 'Pending file selection' ||
      currentStep === 'Pending file upload'
        ? 'Cancel'
        : 'Close'
    "
    (click)="onClose()"
  >
    <mat-icon
      class="modal__header__icon"
      aria-hidden="false"
      [attr.aria-label]="
        currentStep === 'Pending file selection' ||
        currentStep === 'Pending file upload'
          ? 'Cancel'
          : 'Close'
      "
    >
      close
    </mat-icon>
  </button>
</div>

<div class="modal-body">
  <ng-container
    *ngIf="
      currentStep === 'Pending file selection';
      then selectFileTemplate;
      else importFileTemplate
    "
  >
  </ng-container>

  <ng-template #selectFileTemplate>
    <h6 class="mb-4">
      Please take note that this import function is only for intraday trading
      template starting from v0.5 and above.
    </h6>

    <app-file-selection
      [options]="fileSelectionOptions"
      (acceptFile)="onAcceptFile($event)"
      (rejectFile)="onRejectFile($event)"
    ></app-file-selection>
  </ng-template>

  <ng-template #importFileTemplate>
    <div class="modal__body__container">
      <div class="d-flex flex-wrap">
        <div class="flex-grow-1">
          <span>{{ intradayTradingFile.file.name }}</span>
          <ngb-progressbar
            *ngIf="intradayTradingFile.status === 'UPLOADING'"
            [animated]="true"
            height="5px"
            [striped]="true"
            type="success"
            [value]="intradayTradingFile.uploadProgressPercentage"
          ></ngb-progressbar>
        </div>

        <div class="ml-2">
          <mat-spinner
            *ngIf="currentStep === 'Polling file information'"
            diameter="20"
          ></mat-spinner>
        </div>

        <div class="ml-2">
          <div
            class="badge"
            [ngClass]="classMap[intradayTradingFile.status]"
          >
            {{ intradayTradingFile.status }}
          </div>
        </div>
      </div>

      <div
        *ngIf="intradayTradingFile.message !== ''"
        class="mt-4"
      >
        <span
          class="modal__body__message"
          [ngClass]="{
            'modal__body__message--error':
              intradayTradingFile.status === 'UPLOAD FAILED' ||
              intradayTradingFile.status === 'IMPORT FAILED'
          }"
        >
          {{ intradayTradingFile.message }}
        </span>
      </div>
    </div>
  </ng-template>
</div>

<div class="modal-footer">
  <button
    class="btn btn-outline-dark"
    [disabled]="
      currentStep === 'Uploading file' ||
      currentStep === 'Polling file information'
    "
    type="button"
    (click)="onClose()"
  >
    {{
      currentStep === 'Pending file selection' ||
      currentStep === 'Pending file upload'
        ? 'CANCEL'
        : 'CLOSE'
    }}
  </button>
  <button
    *ngIf="
      currentStep === 'Pending file upload' ||
      currentStep === 'Uploading file' ||
      currentStep === 'Pending file information polling' ||
      currentStep === 'Polling file information'
    "
    class="btn btn-primary"
    [disabled]="
      currentStep === 'Uploading file' ||
      currentStep === 'Polling file information'
    "
    onclick="this.blur()"
    type="button"
    (click)="onImportFile()"
  >
    IMPORT
  </button>
</div>
