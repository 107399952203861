import { Injectable } from '@angular/core';
import { formatDate } from "@angular/common";

@Injectable({
  providedIn: 'root'
})

export class DategenService {

  constructor() { }

  taskDate(dtstring) {
    if (dtstring==null) return "";
    let splitarr = dtstring.split(" ");
    let splitdt = splitarr[0].split("-");
    if (dtstring.includes(":"))
    {
      let splittime = splitarr[1].split(":");
      return splitdt[1] + "/" + splitdt[2] + "/" + splitdt[0] + " " + splitarr[1];
    }
    else
    {
      return splitdt[1] + "/" + splitdt[2] + "/" + splitdt[0];
    }
  } 

  dateProcess(datestring,format,datepreefix,exceptionmsg) {    
    try{
        datestring.replace(/ /g,"T");
        let newDate = formatDate(this.taskDate(datestring),format,"en-US","+1600");
        if (newDate == "Invalid Date") {
            throw new Error(`invalid date value ${ newDate }`);
        }
        return datepreefix + newDate;       
      }
      catch (Exception) {        
        return exceptionmsg;
      }                
  }

}
