import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../services/storage.service';

@Injectable({ providedIn: 'root' })

/**
 * Class VerifyGuard is responsible for checking if user verified profile
 */
export class VerifyGuard implements CanActivate {
    constructor(
        private router: Router,        
        private storageService: StorageService,
        private authService: AuthService,
        private toastr: ToastrService
    ) { }

    /**
     * Checks if a user can activate the route
     * @param route 
     * @param state 
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // If profile still unverified, route to first login wizard to verify profile
        if (!!this.authService.isLoggedIn() && !!this.storageService.getFlag('verifyProfile') && !this.storageService.getFlag('requireChangePassword')) {
            this.toastr.info('You need to verify your profile details before proceeding', 'Redirected');
            this.router.navigate(['/first-login-wizard']);
            return false;
        }
        // If user is logged in
        else {
            return true;
        }
    }
}