import { Component, OnInit,ViewChildren } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { formatDate } from "@angular/common";
import { SingleDataSet, Label } from 'ng2-charts';
import { ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { AuthService } from '../services/auth.service';
import { ApiService } from '../services/api.service';
import {lifeWheelColors} from '../shared/event-constants';
import {DategenService} from '../services/dategen.service';
import { DatePipe } from '@angular/common'


@Component({
  selector: 'app-coachreflection',
  templateUrl: './coachreflection.component.html',
  styleUrls: ['./coachreflection.component.scss']
})

export class CoachreflectionComponent implements OnInit {

  public polarAreaChartLabels: Label[] = ['Work/Career/Mission','Financial','Hobby/Passion/Fun','Relationship','Health','Growth/Personal Development','Contribution','Spiritual/Finding Meaning'];
  public polarAreaLegend = true;
  public polarAreaChartType: ChartType = 'polarArea';
  public polarAreaPlugins = [pluginDataLabels];
  mainData: any;
  loadingBar: boolean = true;
  maintable: any;
  columnsData:any;
  mainrangequestions: any;
  studentslist: any;
  lwcolors:any;

  @ViewChildren('wheel1') wheel1: any;
  @ViewChildren('wheel2') wheel2: any;

  private options = {
    responsive:true,
    scale: {
      ticks: {
          beginAtZero: true,
          // stepSize: 1,
          min: 0,
          max: 10,
          fontSize:20,
      },
    },
    legend: {
      position:"left",
      labels: {
        fontSize:20,
      }
    },
    plugins: {
      datalabels: {
        color: 'blue',
        font:{
          size:20,
        },
        labels: {
          label: {
          },
          value: {
          }
        }
      }
    }
  };

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private dategenService: DategenService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    let param = {
      "page_name":"Life Wheel",
      "workshop_code":"all"
    };

    this.apiService.getReflectionsCoachData(param).subscribe(data => {
      //if (!this.authService.authCheckApi(data)) return;
      if (data.code === 0) {
        this.mainData = data.result;
        this.processData();
      }
      this.loadingBar = false;

    });

    console.log("showing life wheel colors");
    console.log(lifeWheelColors);
    this.lwcolors = lifeWheelColors;

  }

  ngOnViewInit() {


  }

  download () {
    console.log(this.wheel1);

    this.wheel1.forEach((child,index) => {
      //setTimeout(function () {
        var a = document.createElement('a');
        a.href = child.nativeElement.toDataURL();
        a.download = child.nativeElement.textContent.trim()+".png";
        a.click();
        a.remove();
      //},index*1000);

  });

  this.wheel2.forEach((child,index) => {
    //setTimeout(function () {
      var a = document.createElement('a');
      a.href = child.nativeElement.toDataURL();
      a.download = child.nativeElement.textContent.trim()+".png";
      a.click();
      a.remove();
    //},index*1000);

  });
  }

  processData() {
    this.mainrangequestions = this.mainData.questions.filter(x => x.type == "RANGE");
    let questionids = this.mainrangequestions.map(item => item.id).filter((value, index, self) => self.indexOf(value) === index);
    let studentslist = this.mainData.feedback.map(item => item.student_id).filter((value, index, self) => self.indexOf(value) === index);
    this.maintable = Array();
    for (let k=0;k<studentslist.length;k++)
    {
      let feedbacklist = this.mainData.feedback.filter(x=>questionids.includes(x.reflection_question_id)&&x.student_id==studentslist[k]);
      if (feedbacklist.length>15)
      {
        let student = this.mainData.students.filter(x=>x.student_id==studentslist[k]);
        if (student.length>0)
        {
          let recorditem = Array();
          recorditem["name"] = student[0].given_name;
          recorditem["studentid"] = student[0].student_id;
          recorditem["updatedate"] = null;

          let lw1 = Array();
          for (let j=0;j<8;j++){

            if (+feedbacklist[j].value<0)
              lw1.push(0);
            else
            {
              lw1.push(+feedbacklist[j].value);
              let dt = this.datepipe.transform(feedbacklist[j].updated_at,"medium");
              recorditem["updatedate"] = (dt=="Error Processing Date")?recorditem["updatedate"]:dt;
            }
          }

          recorditem["Lifewheel1"] = lw1;

          let lw2 = Array();
          for (let j=8;j<16;j++)
          {
            if (+feedbacklist[j].value<0)
              lw2.push(0);
            else
            {
              lw2.push(+feedbacklist[j].value);
              let dt = this.datepipe.transform(feedbacklist[j].updated_at,"medium");
              recorditem["updatedate"] = (dt=="Error Processing Date")?recorditem["updatedate"]:dt;
            }
          }
          recorditem["Lifewheel2"] = lw2;

          this.maintable.push(recorditem);
        }
      }
    }

    this.dataSource = new MatTableDataSource(this.maintable);
  }

  displayedColumns: string[] = ['name', 'studentid','Lifewheel1','updatedate'];
  dataSource:any

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
