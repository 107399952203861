import { Injectable } from '@angular/core';

export interface StockItem {
    name: string;
    code: string;        
    img?:string;    
  }

const STOCK_ITEMS:any = 
[
  {
    name:"Microsoft",
    code:"MSFT",
    img:"https://eodhistoricaldata.com/img/logos/US/MSFT.png"
  },
  {
    name:"Tesla",
    code:"TSLA",
    img:"https://eodhistoricaldata.com/img/logos/US/TSLA.png"
  },
  {
    name:"Google",
    code:"GOOG",
    img:"https://eodhistoricaldata.com/img/logos/US/goog.png"
  }
];


export const BI_GIX_FILTER:any = 
{
  institutions_ownership:60,
  roa_ttm:10,
  roe_ttm:15,
  eps_growth_0y:10,
  eps_growth_1y:10,
  revenue_growth_cagr_5y:5,
  income_growth_cagr_5y:5,
  avg_volume:1000000
};

@Injectable()
export class StockItems {
  getStockItems(): StockItem[] {
    return STOCK_ITEMS; 
  }  
      
}