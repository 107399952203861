import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

import { humanizeBytes } from '../../functions';
import { FileSelectionOptions } from '../../interfaces';

@Component({
  selector: 'app-file-selection',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './file-selection.component.html',
  styleUrls: ['./file-selection.component.scss'],
})
export class FileSelectionComponent implements OnInit {
  @Input()
  options: FileSelectionOptions;

  @Input()
  contentTemplate: TemplateRef<any>;

  @Output()
  acceptFile = new EventEmitter<File>();

  @Output()
  rejectFile = new EventEmitter<string>();

  imageMap: { [key: string]: string } = {
    pending: 'assets/images/file-upload/upload-to-cloud.png',
    dragging: 'assets/images/file-upload/upload-to-cloud.gif',
  };

  imageKey = 'pending';

  hint: string;

  ngOnInit() {
    this.options = Object.assign(
      {
        maximumNumberOfFiles: 1,
        maximumFileSize: Number.POSITIVE_INFINITY,
        disallowedFilenameCharacters: '',
        fileExtensions: '*',
      } as FileSelectionOptions,
      this.options,
    );

    this.options.fileExtensions =
      typeof this.options.fileExtensions === 'string' &&
      this.options.fileExtensions !== '*'
        ? [this.options.fileExtensions]
        : this.options.fileExtensions;

    if (!this.contentTemplate) {
      this.hint = `${
        typeof this.options.fileExtensions === 'string'
          ? ''
          : this.options.fileExtensions!.join(', ')
      } file${this.options.maximumNumberOfFiles! > 1 ? 's' : ''}${
        this.options.maximumFileSize === Number.POSITIVE_INFINITY
          ? ''
          : ' (max. '
              .concat(humanizeBytes(this.options.maximumFileSize!))
              .concat(')')
      }`;
    }
  }

  onEnterDropZone() {
    this.imageKey = 'dragging';
  }

  onLeaveDropZone() {
    this.imageKey = 'pending';
  }

  onDropFile(fileList?: FileList) {
    this.checkFile(fileList);
  }

  onChangeFile(fileList?: FileList) {
    this.checkFile(fileList);
  }

  protected checkFile(fileList?: FileList) {
    const files = fileList ?? [];

    if (files.length > this.options.maximumNumberOfFiles!) {
      this.rejectFile.emit('Only 1 file is allowed.');

      return;
    }

    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      const filenameParts = file.name.split('.');

      if (typeof this.options.fileExtensions !== 'string') {
        const fileExtension = filenameParts.pop() ?? '';

        if (
          fileExtension === '' ||
          !this.options.fileExtensions!.includes(`.${fileExtension}`)
        ) {
          this.rejectFile.emit(`${file.name} format is invalid.`);

          continue;
        }
      }

      if (
        this.options.disallowedFilenameCharacters &&
        this.options.disallowedFilenameCharacters !== ''
      ) {
        const regex = new RegExp(
          `[${this.options.disallowedFilenameCharacters}]`,
        );
        const filenameWithoutExtension = filenameParts.join('.');

        if (regex.test(filenameWithoutExtension)) {
          this.rejectFile.emit(
            `${file.name} can't contain any of the following characters: ${this.options.disallowedFilenameCharacters}`,
          );

          continue;
        }
      }

      if (file.size <= 0) {
        this.rejectFile.emit(`${file.name} size must be greater than 0 bytes.`);

        continue;
      }

      if (file.size > this.options.maximumFileSize!) {
        this.rejectFile.emit(`${file.name} size exceeds the allowable limit.`);

        continue;
      }

      this.acceptFile.emit(file);
    }
  }
}
