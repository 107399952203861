<div class="main-container">
    <mat-toolbar style="background-color:white;" class="topbar telative">
        <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed" [hidden]="!!mobileQuery.matches">
            <mat-icon>menu</mat-icon>
        </button>
        <div class="">
            <a class="navbar-brand" [routerLink]="[ '/', 'reflection' ]" routerLinkActive="active">
                <b>
                    <img src="assets/images/bilogo.png" class="light-logo" alt="homepage"
                        [height]="!!mobileQuery.matches ? 38: 24" style="margin-left: 5px;">
                </b>
                <!--End Logo icon -->
            </a>
        </div>
        <div>
            <div style="font-size:0.8rem;margin-left:-0.8rem;color: #444;">
                STUDENT PORTAL
            </div>
        </div>

        <span fxFlex>
        </span>
        <div style="width:250px;" *ngIf="isLoading | async">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
        <div ngbDropdown #myDrop="ngbDropdown">
            <button *ngIf="!isProd" mat-icon-button id="Notifdropdown" class="m-r-5" ngbDropdownToggle>
                <mat-icon matBadge={{Counter}} matBadgeColor="warn">notifications_active</mat-icon>
            </button>
            <div ngbDropdownMenu class="dropdown" aria-labelledby="Notifdropdown">
                <div >
                    <app-notifsidebar [count]=Counter (countChanged)="countChangedHandler($event)" (toClose)="myDrop.close();"></app-notifsidebar>
                </div>
            </div>
        </div>
        <button mat-icon-button (click)="rnav.toggle()" value="sidebarclosed">
            <mat-icon>account_circle</mat-icon>
        </button>
    </mat-toolbar>
    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    >
        <mat-sidenav #snav id="snav" style="background-color: #0079C1;" class="dark-sidebar pl-xs"
            [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0" [opened]="mobileQuery.matches"
            [disableClose]="mobileQuery.matches" (mouseenter)="toggleSidebar()" (mouseleave)="toggleSidebar()"
            [ngStyle]="{
                'width': mini==true&&mobileQuery.matches ? '70px' : '250px',
                'visibility': mobileQuery.matches ? 'visible' : 'hidden'
              }" [class.mat-elevation-z8]="true">
            <app-sidebar></app-sidebar>
        </mat-sidenav>
        <mat-sidenav-content class="page-wrapper" style="margin-left:80px;" [ngStyle]="{
            'margin-left': mobileQuery.matches ? '80px' : '0'
        }">

            <div class="page-content" style="max-width:1920px;">

                <router-outlet>
                </router-outlet>

            </div>

        </mat-sidenav-content>


        <mat-sidenav #rnav id="rnav" style="background-color: #F8F8F8;" position="end" class="dark-sidebar pl-xs"
            [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0" [disableClose]="mobileQuery.matches">
            <right-sidebar></right-sidebar>
        </mat-sidenav>
    </mat-sidenav-container>

</div>
