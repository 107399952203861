import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { AngularMaterialModule } from '../angular-material-module';
import { CdkTableModule, CdkColumnDef  } from '@angular/cdk/table';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SPRoutes } from './sp.routing';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SpRichTextEditorComponent } from './sp-rich-text-editor/sp-rich-text-editor.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ReadMoreComponent } from './read-more/read-more.component';
import { VideoplayerComponent } from './videoplayer/videoplayer.component';
import { DoubleScrollComponent } from './double-scroll/double-scroll.component';

import { SingleReflectionPageComponentComponent } from './single-reflection-page-component/single-reflection-page-component.component';
import { ValueReflectionPageComponent } from './value-reflection-page/value-reflection-page.component';
import { LifeWheelReflectionPageComponent } from './life-wheel-reflection-page/life-wheel-reflection-page.component';
import { LifeWheelSingleComponent } from './life-wheel-single/life-wheel-single.component';

import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';


import {MatTooltipModule} from '@angular/material/tooltip';


import { ChartsModule } from 'ng2-charts';
import {MatFormField} from '@angular/material/form-field';
import { CoachtableComponent } from './coachtable/coachtable.component';
import { DataTablesModule } from 'angular-datatables';
import { SingleReflectionCoachComponent } from './single-reflection-coach/single-reflection-coach.component';
import { SingleReflectionReadOnlyComponent } from './single-reflection-read-only/single-reflection-read-only.component';
import { SingleReflectionTableComponent } from './single-reflection-table/single-reflection-table.component';
import {SharedModule} from '../shared/shared.module';
import { StockchartComponent } from './stockchart/stockchart.component';
import { OnboardPopupComponent } from './onboard-popup/onboard-popup.component';
import { SingleReflectionSurveymonkeyComponent } from './single-reflection-surveymonkey/single-reflection-surveymonkey.component';
import { SupportComponent } from './support/support.component';
import { TncPopupComponent } from './tnc-popup/tnc-popup.component';
import { MatpPopupComponent } from './matp-popup/matp-popup.component'
import { BusinessIntelligenceComponent } from './business-intelligence/business-intelligence.component';
import { InformationalPopupComponent } from './informational-popup/informational-popup.component';

import {MyCommonModule} from '../my-common/my-common.module';

@NgModule({
  imports: [
    CommonModule,
    MyCommonModule,
    RouterModule.forChild(SPRoutes),
    AngularMaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CdkTableModule,
    CKEditorModule,
    AngularMultiSelectModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    ChartsModule,
    MatTooltipModule,    
    DataTablesModule,
    SharedModule
  ],
  exports: [
    VideoplayerComponent,
    DoubleScrollComponent,
    SingleReflectionPageComponentComponent,
    ValueReflectionPageComponent,
    LifeWheelReflectionPageComponent,
    LifeWheelSingleComponent,
    CoachtableComponent,
    SingleReflectionCoachComponent,
    SingleReflectionReadOnlyComponent,
    SingleReflectionTableComponent,
    StockchartComponent,
    OnboardPopupComponent,
    SingleReflectionSurveymonkeyComponent,
    SupportComponent,
    BusinessIntelligenceComponent,
    InformationalPopupComponent,
  ],
  providers: [CdkColumnDef],
  declarations: [
    SpRichTextEditorComponent,
    ReadMoreComponent,
    VideoplayerComponent,
    DoubleScrollComponent,
    SingleReflectionPageComponentComponent,
    ValueReflectionPageComponent,
    LifeWheelReflectionPageComponent,
    LifeWheelSingleComponent,
    CoachtableComponent,
    SingleReflectionCoachComponent,
    SingleReflectionReadOnlyComponent,
    SingleReflectionTableComponent,
    StockchartComponent,
    OnboardPopupComponent,
    SingleReflectionSurveymonkeyComponent,
    SupportComponent,
    TncPopupComponent,
    MatpPopupComponent,
    BusinessIntelligenceComponent,
    InformationalPopupComponent,
  ]
})
export class SPComponentsModule {}
