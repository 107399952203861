import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';

@Injectable({ providedIn: 'root' })

/**
 * Class AuthGuard is responsible for functions related to route navigation
 */
export class AuthGuard implements CanActivate {
    
    constructor(
        private router: Router,
        private authService: AuthService,
        private storageService: StorageService,
        private toastr: ToastrService
    ) { }

    /**
     * Checks if a user can activate the route
     * @param route 
     * @param state 
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let url: string = state.url;
        // If user is not logged in, redirect to login page
        if (!this.authService.isLoggedIn()) { 
            this.storageService.signOut();           
            this.authService.redirectUrl = url;
            this.toastr.info('You need to log in before you can access this page', 'Redirected');
            this.router.navigate(['/login']);
            return false;
        }
        // If user is logged in
        else {
            return true;
        }
    }
}