import { HttpEvent, HttpEventType } from '@angular/common/http';

import { Observable } from 'rxjs';
import { distinctUntilChanged, scan } from 'rxjs/operators';

import { HttpEventProgress } from '../interfaces';

export function reportHttpEventProgress(): (
  observable: Observable<HttpEvent<any>>,
) => Observable<HttpEventProgress> {
  const initialState: HttpEventProgress = {
    httpEventType: HttpEventType.User,
    uploadProgress: {
      percentage: 0,
    },
    response: {
      body: null,
    },
  };

  const reduceState = (
    previousState: HttpEventProgress,
    httpEvent: HttpEvent<any>,
  ): HttpEventProgress => {
    switch (httpEvent.type) {
      case HttpEventType.Sent:
        return <HttpEventProgress>{
          httpEventType: httpEvent.type,
          uploadProgress: {
            percentage: 0,
          },
        };

      case HttpEventType.UploadProgress:
        return <HttpEventProgress>{
          httpEventType: httpEvent.type,
          uploadProgress: {
            percentage: httpEvent.total
              ? Math.round((httpEvent.loaded * 100) / httpEvent.total)
              : previousState.uploadProgress.percentage,
          },
        };

      case HttpEventType.Response:
        return <HttpEventProgress>{
          httpEventType: httpEvent.type,
          uploadProgress: {
            percentage: 100,
          },
          response: {
            body: httpEvent.body,
          },
        };

      default:
        return previousState;
    }
  };

  return (observable) =>
    observable.pipe(
      scan(reduceState, initialState),
      distinctUntilChanged(
        (a, b) =>
          a.httpEventType === b.httpEventType &&
          a.uploadProgress.percentage === b.uploadProgress.percentage,
      ),
    );
}
