export function humanizeBytes(bytes: number) {
  if (bytes === 0) {
    return '0 byte';
  }

  const kilobytes = 1024;
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  const factor = Math.floor(Math.log(bytes) / Math.log(kilobytes));

  return `${parseFloat((bytes / Math.pow(kilobytes, factor)).toFixed(2))} ${
    units[factor]
  }`;
}
