import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { AuthService } from '../../../services/auth.service';
import { StorageService } from '../../../services/storage.service';
import Swal from 'sweetalert2';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {OnboardPopupComponent} from '../../../components/onboard-popup/onboard-popup.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class AppSidebarComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private authService: AuthService,
    private storageService: StorageService,
    private modalService: NgbModal
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 900px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);


  }

  panelOpenState = false;

  studentName:string;

  open(item) {
    const modalRef = this.modalService.open(OnboardPopupComponent, {ariaLabelledBy: 'modal-basic-title', size: 'xl'});
    modalRef.componentInstance.url = item.video_url;
    modalRef.componentInstance.title = item.name;
    console.log(item);
  }

  /**
   * Facebook Login
   */
  loginWithFacebook() {
    this.authService.facebookLogin();
  }

  logoutFB() {
    this.authService.FBLogout();
  }

  setOpen(name,isOpen)
  {    
    this.storageService.saveFlag(isOpen,name);

  }

  getOpen(name)
  {
    let fl = this.storageService.getFlag(name);
    return fl;
  }

  changePassword() {
    var template =
    '<input class="swal2-input" id="old_password" placeholder="Old Password *"' +
    'type="password" name="old_password">' +

    '<input class="swal2-input" id="new_password" placeholder="New Password *"' +
    'type="password" name="new_password">' +

    '<input class="swal2-input" id="password_confirmation" placeholder="Confirm Password *" ' +
    'type="password" name="password_confirmation">';

    Swal.fire({
      title: 'Change Password',
      html: template,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
      heightAuto: false,
      preConfirm: () => {
        var oldPassword = (<HTMLInputElement>document.getElementById('old_password')).value;
        var newPassword = (<HTMLInputElement>document.getElementById('new_password')).value;
        var passwordConfirmation = (<HTMLInputElement>document.getElementById('password_confirmation')).value

        // Validate input
        if (oldPassword === '' || newPassword === '' || passwordConfirmation === '') {
          Swal.showValidationMessage('All fields are required');
        }
        else if ((oldPassword.length < 8) || (newPassword.length < 8) || (passwordConfirmation.length < 8)) {
          Swal.showValidationMessage('Password needs to be 8 characters long');
        }
        else if (/[^a-zA-Z0-9#?!@$%^&*-]/.test(newPassword) || /[^a-zA-Z0-9#?!@$%^&*-]/.test(passwordConfirmation)) {
          Swal.showValidationMessage('Only alphanumeric characters with symbols are allowed');
        }
        else if (!/[a-z]/.test(newPassword) || !/[a-z]/.test(passwordConfirmation)) {
          Swal.showValidationMessage('Password needs at least a lowercase character');
        }
        else if (!/[A-Z]/.test(newPassword) || !/[A-Z]/.test(passwordConfirmation)) {
          Swal.showValidationMessage('Password needs at least an uppercase character');
        }
        else if (!/[0-9]/.test(newPassword) || !/[0-9]/.test(passwordConfirmation)) {
          Swal.showValidationMessage('Password needs at least a number');
        }
        else {
          Swal.resetValidationMessage(); // Reset the validation message.
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        var oldPassword = (<HTMLInputElement>document.getElementById('old_password')).value;
        var newPassword = (<HTMLInputElement>document.getElementById('new_password')).value;
        var passwordConfirmation = (<HTMLInputElement>document.getElementById('password_confirmation')).value

        var credentials = {
          old_password: oldPassword,
          password: newPassword,
          password_confirmation: passwordConfirmation,
        }
        this.authService.changePassword(credentials);
      }
    });
  }


  logOut() {
    this.authService.logout();
  }

  ngOnInit(): void {
    this.studentName = this.storageService.getStudentName();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
