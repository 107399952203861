import { ChangeDetectorRef, ViewChild, Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { AuthService } from '../../../services/auth.service';
import { StorageService } from '../../../services/storage.service';
import Swal from 'sweetalert2';
import { NavigationService } from 'app/services/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'right-sidebar',
  templateUrl: './rightsidebar.component.html',
  styleUrls: ['./rightsidebar.component.scss']
})
export class RightSidebarComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private authService: AuthService,
    private storageService: StorageService,
    private navigationService: NavigationService,
    private router: Router
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  // Output for triggering function in full.component
  // @Output() navToggle = new EventEmitter<boolean>();
  
  // // Trigger the navToggle event
  // toggle() {
  //   this.navToggle.emit(true);
  // }

  // Toggle the navigation via service
  toggleNav() {
    this.navigationService.rightnav.toggle();
  }

  renavigatePage(state, childstate, param) {
    this.router.navigate(['/'+ state +'/' + childstate, param]);
    // window.location.reload();
  }
  

  panelOpenState = false;

  studentName: string;

  /**
  * Facebook connection
  */
  connectToFacebook() {
    this.authService.facebookLogin();
  }

  disconnectFromFacebook() {
    // Swal pop up to confirm disconnect
    Swal.fire({
      title: 'Confirm disconnect Facebook account?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Disconnect',
      heightAuto: false
    }).then((result) => {
      if (result.isConfirmed) {
        // Call disconnect from Facebook API
        this.authService.disconnectFromFacebookApi();
      }
    });
  }

  changePassword() {
    var template =
      '<input class="swal2-input" id="old_password" placeholder="Old Password *"' +
      'type="password" name="old_password">' +

      '<input class="swal2-input" id="new_password" placeholder="New Password *"' +
      'type="password" name="new_password">' +

      '<input class="swal2-input" id="password_confirmation" placeholder="Confirm Password *" ' +
      'type="password" name="password_confirmation">';

    Swal.fire({
      title: 'Change Password',
      html: template,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
      heightAuto: false,
      preConfirm: () => {
        var oldPassword = (<HTMLInputElement>document.getElementById('old_password')).value;
        var newPassword = (<HTMLInputElement>document.getElementById('new_password')).value;
        var passwordConfirmation = (<HTMLInputElement>document.getElementById('password_confirmation')).value

        // Validate input
        if (oldPassword === '' || newPassword === '' || passwordConfirmation === '') {
          Swal.showValidationMessage('All fields are required');
        }
        else if ((oldPassword.length < 8) || (newPassword.length < 8) || (passwordConfirmation.length < 8)) {
          Swal.showValidationMessage('Password needs to be 8 characters long');
        }
        else if (/[^a-zA-Z0-9#?!@$%^&*-]/.test(newPassword) || /[^a-zA-Z0-9#?!@$%^&*-]/.test(passwordConfirmation)) {
          Swal.showValidationMessage('Only alphanumeric characters with symbols are allowed');
        }
        else if (!/[a-z]/.test(newPassword) || !/[a-z]/.test(passwordConfirmation)) {
          Swal.showValidationMessage('Password needs at least a lowercase character');
        }
        else if (!/[A-Z]/.test(newPassword) || !/[A-Z]/.test(passwordConfirmation)) {
          Swal.showValidationMessage('Password needs at least an uppercase character');
        }
        else if (!/[0-9]/.test(newPassword) || !/[0-9]/.test(passwordConfirmation)) {
          Swal.showValidationMessage('Password needs at least a number');
        }
        else {
          Swal.resetValidationMessage(); // Reset the validation message.
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        var oldPassword = (<HTMLInputElement>document.getElementById('old_password')).value;
        var newPassword = (<HTMLInputElement>document.getElementById('new_password')).value;
        var passwordConfirmation = (<HTMLInputElement>document.getElementById('password_confirmation')).value

        var credentials = {
          old_password: oldPassword,
          password: newPassword,
          password_confirmation: passwordConfirmation,
        }
        this.authService.changePassword(credentials);
      }
    });
  }

  logOut() {
    this.authService.logout();
  }

  ngOnInit(): void {
    this.studentName = this.storageService.getStudentName();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
