import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  @Input()
  visible:boolean = true;

  @Input()
  feedback:boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
