import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, ViewChild, Component, OnDestroy, AfterViewInit } from '@angular/core';
import {Observable,EMPTY} from 'rxjs';
import { NgForm,FormControl, FormGroup, Validators,ValidatorFn,FormGroupDirective,ValidationErrors } from '@angular/forms'
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { NavigationService } from '../../services/navigation.service';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api.service';
import { StockItem,StockItems } from '../../shared/stock-details/stock-items';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LoaderService } from '../../services/loader.service';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['full.component.scss']
})
export class FullComponent implements OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  value = '';
  isProd: boolean;
  mini:boolean = true;
  // Set rnav variable
  @ViewChild('rnav') public rnav;
  Counter : number;
  filteredStocks: Observable<any>;
  apiFilteredData: StockItem[] = [];
  env: any;
  myControl = new FormControl();
  isLoading: Subject<boolean> = this.loader.isLoading;



  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public navigationService: NavigationService,
    private apiService: ApiService,
    private router:Router,
    public loader:LoaderService
  ) {

    this.mobileQuery = media.matchMedia('(min-width: 1400px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.isProd = environment.production||environment.testing;
  }

  ngOnInit():void {

  }


  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() {
    // Defining the right nav
    [].forEach.call(document.querySelectorAll('.mat-expansion-indicator'), function (el:HTMLElement) {
      el.style.display = 'none';
    });
    this.navigationService.rightnav = this.rnav;
    this.filteredStocks = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
          return this._filterStocks(val)
        }),
      map((data) => {
          return data.result;
      }),
      tap (data => console.log(data))
    )

    console.log(this.menuItems.getMenuitem());
  }

  _filterStocks(value: string): Observable<any> {
    const filterValue = value.toUpperCase();
    let param = {
      "search" : filterValue
    }
    return this.apiService.getStockAutocomplete(param).pipe(
    );
  }




  onSelectionChanged(event) {
    if (event.option.value!="")
      this.router.navigate(['/investors/analyst'], { queryParams: { symbol:event.option.value } });
      //this.myStockDetailComponent.processRequestForStock(event.option.value);
  }

  toggleSidebar() {
    if (!this.mobileQuery.matches) return;
    if (this.mini) {
      [].forEach.call(document.querySelectorAll('.mat-expansion-indicator'), function (el:HTMLElement) {
        el.style.display = 'block';
      });
      this.mini = false;
    } else {
      [].forEach.call(document.querySelectorAll('.mat-expansion-indicator'), function (el:HTMLElement) {
        el.style.display = 'none';
      });
      this.mini = true;
     }
    }

    countChangedHandler(count: number) {
      this.Counter = count;
      console.log(count);
    }


}
