import { NativeDateAdapter, MatDateFormats } from '@angular/material/core';
import { DatePipe } from '@angular/common';

// Class for custom date adapter for display format
export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): any {
    if (displayFormat === 'input') {
      let formattedDate = new DatePipe('en-US').transform(date, 'dd-MM-yyyy');
      return formattedDate;
    } 
    else {
      return date.toDateString();
    }
  }
}

// Constant for date formats
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'numeric'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};

export function compressImage(base64image) {
  return new Promise((res, rej) => {
    const img = new Image();
    img.src = base64image;
    img.onload = () => {
      var elem = document.createElement('canvas');
      var aspectRatio = img.height/img.width;
      var newWidth = img.width * 0.3 > 1280 ? 1280 * aspectRatio : img.width * 0.3;
      var newHeight = img.height * 0.3 > 720  ? 720 * aspectRatio : img.height * 0.3;
      elem.width = newWidth;
      elem.height = newHeight;
      var ctx = elem.getContext('2d');
      ctx?.drawImage(img, 0, 0, newWidth, newHeight);
      const data = elem.toDataURL();
      res(data);
    }    
    img.onerror = error => rej(error);
  })
}