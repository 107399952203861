import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/services/auth.service';

import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter, APP_DATE_FORMATS } from '../../globalFunctions';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'app-first-login-wizard',
    templateUrl: './first-login-wizard.component.html',
    styleUrls: ['./first-login-wizard.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})

/**
 * Class for first time login wizard
 */
export class FirstLoginWizardComponent implements OnInit {

    constructor(
        private apiService: ApiService,
        private authService: AuthService,
        public storageService: StorageService,
        private toastr: ToastrService,
        public activatedRoute: ActivatedRoute,
        public route: Router,
        private _formBuilder: FormBuilder
    ) { }

    // Declaring wizard variables
    isLinear: boolean = true; // Flag to determine if can skip steps

    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;
    fourthFormGroup: FormGroup;
    fifthFormGroup: FormGroup;

    matcher = new MyErrorStateMatcher();

    // Initializing variables for personal info page
    originalGivenName: string = '';
    originalFamilyName: string = '';
    originalPhoneNumber: string = '';
    originalEmail: string = '';
    identityDocumentType: string = '';

    // Initializing array to hold countries and states
    countries: any = [];
    states: any = [];

    // Initializing the date of birth
    dateOfBirth = new FormControl(new Date());

    // Initializing the facebook connection flag
    facebookConnected: boolean = false;

    // Initializing array to hold requested fields
    requestedFields:any = [];

    // Get profile details to populate the models
    getProfileDetails() {
        this.apiService.getProfileDetails().subscribe(data => {
            //if (!this.authService.authCheckApi(data)) return;
            console.log(data);
            // Store the original field data to be compared
            this.originalGivenName = !!data.result.given_name ? data.result.given_name : '';
            this.originalFamilyName = !!data.result.family_name ? data.result.family_name : '';
            this.originalPhoneNumber = !!data.result.phone_number ? data.result.phone_number : '';
            this.originalEmail = !!data.result.email ? data.result.email : '';

            this.firstFormGroup.setValue({
                givenName: !!data.result.given_name ? data.result.given_name : '',
                familyName: !!data.result.family_name ? data.result.family_name : '',
                preferredName: !!data.result.preferred_name ? data.result.preferred_name : '',
            });

            this.secondFormGroup.setValue({
                phoneNumber: !!data.result.phone_number ? data.result.phone_number : '',
                email: !!data.result.email ? data.result.email : ''
            });

            this.thirdFormGroup.setValue({
                /*identityDocumentType: !!data.result.is_malaysian ? 'nric' : 'passport',
                nricNumberFirst: !!data.result.is_malaysian ? data.result.identification_number.substr(0, 6) : '',
                nricNumberSecond: !!data.result.is_malaysian ? data.result.identification_number.substr(7, 2) : '',
                nricNumberThird: !!data.result.is_malaysian ? data.result.identification_number.substr(8, 4) : '',
                passportNumber: !data.result.is_malaysian ? data.result.identification_number : '',*/
                dateOfBirth: !!data.result.birth_date ? new Date(data.result.birth_date) : new FormControl(),
                gender: !!data.result.gender ? data.result.gender : '',
                //race: !!data.result.race ? data.result.race : '',
                occupation: !!data.result.occupation ? data.result.occupation : '',
            });

            // Disable the validation check for the wizard
            !!data.result.is_malaysian ? this.disablePassportValidation() : this.disableNRICValidation();

            this.fourthFormGroup.setValue({
                address: !!data.result.address ? data.result.address : '',
                city: !!data.result.city ? data.result.city : '',
                postcode: !!data.result.postcode ? data.result.postcode : '',
                selectedCountry: !!data.result.country_id ? data.result.country_id : '',
                selectedState: !!data.result.state_id ? data.result.state_id : '',
            });

            // Get state selection if there is country selection data
            if (!!data.result.country_id) {
                this.getStates(this.fourthFormGroup.controls.selectedCountry.value);
            }
        });
    }

    // Get countries listing for selection
    getCountries() {
        this.apiService.getCountryList().subscribe(data => {
            this.countries = data.result;
        });
    }

    // Get states listing for selected country
    getStates(selectedCountryId: any) {
        var param = {
            country_id: selectedCountryId
        }
        this.apiService.getStateList(param).subscribe(data => {
            this.states = data.result;
        });
    }

    // Disable passport validation fields when nric is selected, reenable nric validation
    disablePassportValidation() {
        /*this.thirdFormGroup.controls.passportNumber.disable();
        this.thirdFormGroup.controls.nricNumberFirst.enable();
        this.thirdFormGroup.controls.nricNumberSecond.enable();
        this.thirdFormGroup.controls.nricNumberThird.enable();*/
    }

    // Disable nric validation fields when passport is selected, reenable passport validation
    disableNRICValidation() {
        /*this.thirdFormGroup.controls.nricNumberFirst.disable();
        this.thirdFormGroup.controls.nricNumberSecond.disable();
        this.thirdFormGroup.controls.nricNumberThird.disable();
        this.thirdFormGroup.controls.passportNumber.enable();*/
    }

    // Generate the array of fields that will be sent to request changes API
    getRequestedFieldsArray(formControlName: string, fieldName:string, fieldDisplayName: string) {
        // Push to requested fields array only when field is dirty, touched and different value from original value
        if (!!this.firstFormGroup.controls[formControlName].dirty &&
            (this.firstFormGroup.controls[formControlName].value !== this['original' + fieldName]) &&
            this.requestedFields.indexOf(fieldDisplayName) === -1) {
                this.requestedFields.push(fieldDisplayName);
        }
        else if (this.firstFormGroup.controls[formControlName].value === this['original' + fieldName]) {
            this.requestedFields = this.requestedFields.filter(item => {
                return item !== fieldDisplayName
            });
        }
    }

    confirmUpdateProfileInfo() {
        Swal.fire({
            title: 'Confirm profile information?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#0079C1',
            confirmButtonText: 'Confirm',
            heightAuto: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.updateProfile();
            }
        });
    }

    // Updates the profile details with model data
    updateProfile() {
        // Variable for update profile params
        var param = {
            // First form group
            preferred_name: this.firstFormGroup.controls.preferredName.value,
            // Third form group
            // If document type is nric, is Malaysian
            //is_malaysian: this.thirdFormGroup.controls.identityDocumentType.value === 'nric' ? 1 : 0,
            // If document type is nric, pass Malaysian IC number field, else passport number field
            /*identification_number: this.thirdFormGroup.controls.identityDocumentType.value === 'nric' ?
            (this.thirdFormGroup.controls.nricNumberFirst.value  + this.thirdFormGroup.controls.nricNumberSecond.value +
                this.thirdFormGroup.controls.nricNumberThird.value) : this.thirdFormGroup.controls.passportNumber.value,*/
            birth_date: new DatePipe('en-US').transform(this.thirdFormGroup.controls.dateOfBirth.value, 'yyyy-MM-dd'),
            gender: this.thirdFormGroup.controls.gender.value,
            race: 'none',
            occupation: this.thirdFormGroup.controls.occupation.value,
            // Fourth form group
            address: this.fourthFormGroup.controls.address.value,
            city: this.fourthFormGroup.controls.city.value,
            postcode: this.fourthFormGroup.controls.postcode.value,
            country_id: this.fourthFormGroup.controls.selectedCountry.value,
            state_id: this.fourthFormGroup.controls.selectedState.value,
        }

        // Update profile api call
        this.apiService.updateProfileDetails(param).subscribe(data => {
            // If request success
            if (data.code === 0) {
                // If there are requested fields
                if (!!this.requestedFields.length) {
                    var requestParams = {};
                    // For each requested fields
                    for (var i = 0; i < this.requestedFields.length; i++) {
                        // Variable for the parameter name to set the request param values
                        var paramName = this.requestedFields[i].toLowerCase().replace(' ', '_');
                        // Variable for the formcontrolname values
                        var formControlName = this.requestedFields[i].replace(/\s+/g, '');
                        requestParams[paramName] = this.firstFormGroup.controls[formControlName].value;
                    }

                    this.apiService.requestProfileUpdate(requestParams).subscribe();
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Profile updated',
                    showConfirmButton: false,
                    timer: 1500,
                    heightAuto: false
                });

                // Remove the verify profile flag
                this.storageService.removeFlag('verifyProfile');
                // TODO: this will be the front page of SP
                if (this.authService.redirectUrl) {
                    this.route.navigate([this.authService.redirectUrl]);
                    this.authService.redirectUrl = null;
                  }
                else {
                    this.storageService.signOut();
                    this.route.navigate(['/login']);
                }
            }
            // Invalid input detected
            else if (data.code === 3) {
                var errorMessages = '';

                // If there are errors
                if (!!data.errors) {
                    // Get array of error fields
                    var arrayOfFields = Object.keys(data.errors);
                    // Loop the array of error fields
                    for (var i = 0; i < arrayOfFields.length; i++) {
                        // Get the array of messages for each field
                        var arrayOfMessages = data.errors[arrayOfFields[i]];
                        for (var j = 0; j < arrayOfMessages.length; j++) {
                            errorMessages += arrayOfMessages[j] + '\n';
                        }
                    }
                }

                Swal.fire({
                    title: 'Invalid input detected',
                    text: errorMessages,
                    icon: 'warning',
                    confirmButtonText: 'Okay',
                    heightAuto: false
                });
            }
        });
    }

    /**
     * Facebook connection
     */
    connectToFacebook() {
        this.authService.facebookLogin();
        this.authService.FBConnectionStatus().subscribe(data => {
            this.facebookConnected = !!data.result.facebook_user_id;
          });
    }

    // Get the facebook connection status
    getFacebookConnectionStatus() {
        this.authService.FBConnectionStatus().subscribe(data => {
          this.facebookConnected = !!data.result.facebook_user_id;
        });
    }

    ngOnInit(): void {
        this.getProfileDetails();
        this.getFacebookConnectionStatus();
        // Form groups initializing
        this.firstFormGroup = this._formBuilder.group({
            givenName: [{ value: '', disabled: true }, Validators.required],
            familyName: [{ value: '', disabled: true }, Validators.required],
            preferredName: ['', Validators.required],
        });
        this.secondFormGroup = this._formBuilder.group({
            phoneNumber: ['', Validators.compose(
                [
                    Validators.required,
                    Validators.pattern("^((\\+91-?)|0)?[0-9]{10,14}$")
                ]
            )],
            email: ['', Validators.compose(
                [
                    Validators.required,
                    Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
                ]
            )]
        });
        this.thirdFormGroup = this._formBuilder.group({
            /*identityDocumentType: ['', Validators.required],
            nricNumberFirst: ['', Validators.compose(
                [
                    Validators.required,
                    Validators.pattern("[0-9]{6}$")
                ]
            )],
            nricNumberSecond: ['', Validators.compose(
                [
                    Validators.required,
                    Validators.pattern("[0-9]{2}$")
                ]
            )],
            nricNumberThird: ['', Validators.compose(
                [
                    Validators.required,
                    Validators.pattern("[0-9]{4}$")
                ]
            )],
            passportNumber: ['', Validators.compose(
                [
                    Validators.required,
                    Validators.pattern("[a-zA-Z0-9]{6,9}$")
                ]
            )],*/
            dateOfBirth: ['', Validators.required],
            gender: ['', Validators.required],
            /*race: ['', Validators.compose(
                [
                    Validators.required,
                    Validators.pattern("[a-zA-Z]*")
                ]
            )],*/
            occupation: ['', Validators.compose(
                [
                    Validators.required //,
                    //Validators.pattern("[a-zA-Z ]*")
                ]
            )],
        });
        this.fourthFormGroup = this._formBuilder.group({
            address: ['', Validators.required],
            city: ['', Validators.required],
            postcode: ['', Validators.required],
            selectedCountry: ['', Validators.required],
            selectedState: ['', Validators.required],
        });
        this.fifthFormGroup = this._formBuilder.group({
            fifthCtrl: ['', Validators.required]
        });

        this.getCountries();
    }
}
