import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AngularMaterialModule } from '../angular-material-module';

import * as components from './components';
import * as directives from './directives';
import * as services from './services';

@NgModule({
  providers: [...services.default],
  declarations: [...components.default, ...directives.default],
  imports: [CommonModule, HttpClientModule, NgbModule, AngularMaterialModule],
  exports: [...components.default, ...directives.default],
})
export class TradingModule {}
