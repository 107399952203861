<div
  appFileDragAndDrop
  class="p-4 drop-zone"
  (enterDropZone)="onEnterDropZone()"
  (dropFile)="onDropFile($event)"
  (leaveDropZone)="onLeaveDropZone()"
>
  <input
    class="drop-zone__file"
    [accept]="this.options.fileExtensions"
    [multiple]="this.options.maximumNumberOfFiles! > 1"
    title=""
    type="file"
    (change)="onChangeFile($event.target.files)"
  />

  <ng-container
    [ngTemplateOutlet]="contentTemplate || contentDefaultTemplate"
  ></ng-container>
</div>

<ng-template #contentDefaultTemplate>
  <img
    class="drop-zone__image"
    [src]="imageMap[imageKey]"
  />
  <h5 class="mt-4">Drag and drop</h5>
  <h6>or</h6>
  <h5 class="drop-zone__button">Browse</h5>
  <h6 class="my-4 drop-zone__hint">{{ hint }}</h6>
</ng-template>
