// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  testing:false,
  nodeapiUrl:'',
  apiUrl: 'https://student.beyondinsights.net/api/',
  stockapiUrl: 'https://9gad047g2f.execute-api.ap-southeast-1.amazonaws.com/',
  nestApiUrl: '',
  stripePublishKey:'pk_test_51JcRtxB6U2rzv1FWQEIgFLDXPZ58DZujKvYLWB7ce1G4QZFXvipeSYTO6ohrZUVeX6nscNscxro6ZkM4Ip8nUWdk004jRR8rT6',
  googleLogin:'480157565519-i29h22ms4koc85mu0d96buurbjnoadjh.apps.googleusercontent.com',
  facebookLogin:'466499084478293',
  cookiedomain:'student.beyondinsights.net',
  supersetDomain: '',
};
