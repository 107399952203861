<div class="not-box">
    <div class="center-section">
        <p class="profile-name">Notification</p>
    </div>
    <mat-card-content style="margin-bottom: 0;">
        <div class="dropmenu">
            <div *ngFor="let notification of notificationArray; index as i" class="highlightpointer">
                <mat-divider ></mat-divider>
                <mat-nav-list appAccordion>                
                    <div *ngIf="notification.is_readed === true" class="highlightpointer">
                        <div class="div-box">
                            <div class="row" style="position:relative;">
                                <a class="not-tiltle col-xs-12">{{notification.title}}</a>
                                <div class="closemain ">
                                    <a (click)="deleteNotification(notification)"><i class="fa fa-times-circle" style="font-size: 20px;">&nbsp;</i></a>
                                </div>
                            </div>
                            <div class="row" style="margin:0;">
                                <p class="not-content">{{notification.message}}</p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="notification.is_readed === false" class="highlightpointer" (click)="clickNotification(notification)">
                        <div class="div-box">
                            <div class="row" style="position:relative;">
                                <a class="not-tiltle col-xs-12 tw-font-bold" >{{notification.title}}</a>
                                <div class="closemain ">
                                    <a (click)="deleteNotification(notification)"><i class="fa fa-times-circle" style="font-size: 20px;">&nbsp;</i></a>
                                </div>
                            </div>
                            <div class="row" style="margin:0;">
                                <p class="not-content tw-font-medium">{{notification.message}}</p>
                            </div>
                        </div>
                    </div>
                </mat-nav-list>
            </div>
        </div>
    </mat-card-content>
</div>