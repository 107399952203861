import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BiSortableHeaderDirective} from './directive/bi-sortable-header.directive';
import { AutoFocusDirective } from './directive/auto-focus.directive';



@NgModule({
  declarations: [
    BiSortableHeaderDirective,
    AutoFocusDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BiSortableHeaderDirective
  ]
})
export class MyCommonModule { }
