  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="embed-responsive embed-responsive-16by9" style="display:flex;align-items:center;justify-content:center;">
      <iframe *ngIf="url!=''" class="embed-responsive-item" allow="autoplay; fullscreen" frameborder="0" [src]="sanitizedUrl" allowfullscreen></iframe>
      <div *ngIf="url==''">
        Coming soon
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Close</button>
  </div>
