
<div class="center-section">
    <p class="profile-name">{{ studentName }}</p>

</div>

<hr>

<mat-nav-list appAccordion>
    <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems.getRightMenuitem()" routerLinkActive="selected"
        group="{{menuitem.state}}">
        <a class="rightbar tw-px-2.5" appAccordion [routerLink]="'/'+menuitem.state" *ngIf="menuitem.type === 'link'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>

        <mat-accordion *ngIf="menuitem.type === 'accordion'">
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                class="rightbar-accordion mat-elevation-z0">
                <mat-expansion-panel-header class="rightbar accordion">
                    <mat-panel-title>
                        <mat-icon class="accordion-icon black-text">{{ menuitem.icon }}</mat-icon>
                        <span class="black-text">{{ menuitem.name }}</span>
                        <span fxFlex></span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-list class="sub-category">
                    <mat-list-item *ngFor="let submenu of menuitem.subMenuitem" class="black-text">
                        <a *ngIf="submenu.param" (click)="renavigatePage(submenu.state, submenu.childstate, submenu.param)">
                            {{ submenu.name }}
                        </a>
                        <a href="#" *ngIf="!submenu.param" [routerLink]="['/', submenu.state, submenu.childstate]" (click)="toggleNav()">
                            {{ submenu.name }}
                        </a>
                    </mat-list-item>
                </mat-list>
            </mat-expansion-panel>
        </mat-accordion>

    </mat-list-item>
</mat-nav-list>

<hr>

<div class="center-section">
    <button mat-button (click)="logOut()">
        <mat-icon>exit_to_app</mat-icon>
        <span class="magenta">Log Out</span>
    </button>
</div>
