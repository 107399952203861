import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import {ApiService} from '../services/api.service'
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

import * as TradingView from '../../assets/charting_library/charting_library';

import {EOD} from './event-constants'



@Injectable()
export class StudentPortalSaveLoad implements TradingView.IExternalSaveLoadAdapter {

    private client:string = "tradingview";

    async makeApiRequest(token,method,path,params,chartid:number = -1) {
        try {
            const mainheaders = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ token,
                'Accept':'application/json'
              };

            const endpoint: string = environment.apiUrl;
            const url = (chartid==-1)?`${endpoint}${path}?client=${this.client}`:`${endpoint}${path}?client=${this.client}&chart=${chartid}`;
            if (method=="POST")
            {
                    var response = await fetch(url,{
                        method: 'POST',
                        headers:mainheaders,
                        body:JSON.stringify(params)
                    });
            }
            else
            {

                    var response = await fetch(url,{
                        headers:mainheaders,
                    });
            }

            return response.json();
        } catch(error) {
            return {"status":"error"};
        }
    }


    constructor(private storageService:StorageService
    ) {
    }


    public async getAllCharts(): Promise<TradingView.ChartMetaInfo[]>
    {
        const data = await this.makeApiRequest(this.storageService.getToken(),"GET","charts",{});
        return new Promise(resolve => {
            if (data.status=="error")
                resolve(data);
            resolve(data.data);
        });
    }

	removeChart<T extends number | string>(id: T): Promise<void>
    {
        return new Promise(resolve => {
            resolve();
        });
    }

	public async saveChart(chartData: TradingView.ChartData): Promise<number>
    {
        const data = await this.makeApiRequest(this.storageService.getToken(),"POST","charts",chartData);
        return new Promise(resolve => {
            if (data.status=="error")
                resolve(data);
            resolve(data.id);
        });
    }

	public async getChartContent(chartId: number): Promise<string>
    {
        const data = await this.makeApiRequest(this.storageService.getToken(),"GET","charts",{},chartId);
        return new Promise(resolve => {
            if (data.status=="error")
                resolve(data);
            resolve(data.data.content);
        });
    }

	public async getAllStudyTemplates(): Promise<TradingView.StudyTemplateMetaInfo[]>
    {
      const data = await this.makeApiRequest(this.storageService.getToken(),"GET","study_templates",{});
      return new Promise(resolve => {
          if (data.status=="error")
              resolve(data);
          resolve(data.data);
      });
    }

	removeStudyTemplate(studyTemplateInfo: TradingView.StudyTemplateMetaInfo): Promise<void>
    {
        return new Promise(resolve => {
            resolve();
        });
    }

  public async saveStudyTemplate(studyTemplateData: TradingView.StudyTemplateData): Promise<void>
    {
        const data = await this.makeApiRequest(this.storageService.getToken(),"POST","study_templates",studyTemplateData);
        return new Promise(resolve => {
            if (data.status=="error")
                resolve(data);
            resolve(data.id);
        });

    }

    public async getStudyTemplateContent(studyTemplateInfo: TradingView.StudyTemplateMetaInfo): Promise<string>
    {
      const data = await this.makeApiRequest(this.storageService.getToken(),"GET","study_templates",studyTemplateInfo);
      return new Promise(resolve => {
          if (data.status=="error")
              resolve(data);
          resolve(data.data.content);
      });
    }

	getDrawingTemplates(toolName: string): Promise<string[]>
    {
        return new Promise(resolve => {
            resolve([]);
        });
    }

	loadDrawingTemplate(toolName: string, templateName: string): Promise<string>
    {
        return new Promise(resolve => {
            resolve("");
        });
    }

	removeDrawingTemplate(toolName: string, templateName: string): Promise<void>
    {
        return new Promise(resolve => {
            resolve();
        });
    }

	saveDrawingTemplate(toolName: string, templateName: string, content: string): Promise<void>
    {
        return new Promise(resolve => {
            resolve();
        });
    }


}
