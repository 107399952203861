
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
//import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { Routes } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { PasswordChangedGuard } from './helpers/passwordchanged.guard';
import { VerifyGuard } from './helpers/verify.guard';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
/*import { AppHeaderComponent } from './layouts/full/header/header.component';*/
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { RightSidebarComponent } from './layouts/full/rightsidebar/rightsidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material-module';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import {MatTooltipModule} from '@angular/material/tooltip';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login.component';
import { FirstLoginWizardComponent } from './login/first-login-wizard/first-login-wizard.component';

import { ToastrModule } from 'ngx-toastr';

import { AuthInterceptor } from './helpers/auth.interceptor';

import { SPComponentsModule } from './components/sp.module';
import { ChartsModule } from 'ng2-charts';
import { CoachreflectionComponent } from './coachreflection/coachreflection.component';
import { DataTablesModule } from 'angular-datatables';
import { NotifsidebarComponent } from './layouts/full/notifsidebar/notifsidebar.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { environment } from 'environments/environment';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { CoachModule } from './coach/coach.module';
import { LoaderService } from './services/loader.service';

import { TradingModule } from './trading';

const env = environment;

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: env.cookiedomain // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position:'bottom-left',
  palette: {
    popup: {
      background: '#000000',
      text: "#fff"
},
    button: {
      background: '#C41C74'
    }
  },
  content: {
    "href": "https://www.beyondinsights.net/privacy-policy/"
  },
  theme: "edgeless",
  type: 'info'
};

const fbLoginOptions = {
  scope: 'email',
  return_scopes: true,
  enable_profile_selector: true
};

export const AppRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [VerifyGuard]
  },
  {
    path: 'first-login-wizard',
    component: FirstLoginWizardComponent,
    canActivate: [AuthGuard, PasswordChangedGuard]
  },
  {
    path: 'qwerty123',
    component: CoachreflectionComponent
  },
  { path: 'iv', loadChildren: () => import('./investors/investors.module').then(m => m.InvestorsModule) },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
      },
      {
        path: '',
        loadChildren:
          () => import('./qcard/qcard.module').then(m => m.QcardComponentsModule),
      },
      {
        path: '',
        loadChildren:
          () => import('./settings/settings.module').then(m => m.SettingsComponentsModule),
      },
      {
        path: '',
        loadChildren:
          () => import('./components/sp.module').then(m => m.SPComponentsModule),
      },
      {
        path: '',
        loadChildren:
          () => import('./reflection/reflection.module').then(m => m.ReflectionModule),
      },
      {
        path: '',
        loadChildren:
          () => import('./events/events.module').then(m => m.EventsModule),
      },
      {
        path: '',
        loadChildren:
          () => import('./coach/coach.module').then(m => m.CoachModule),
      },
      { path: 'investors', loadChildren: () => import('./investors/investors.module').then(m => m.InvestorsModule) },
      { path: 'subscription', loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule) },
      { path: 'surveys', loadChildren: () => import('./surveys/surveys.module').then(m => m.SurveysModule) },
      // {
      //   path: 'dashboard',
      //   loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      // }
    ]
  },
  



];

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    /*AppHeaderComponent,*/
    SpinnerComponent,
    AppSidebarComponent,
    RightSidebarComponent,
    LoginComponent,
    FirstLoginWizardComponent,
    CoachreflectionComponent,
    NotifsidebarComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    NgbModule,
    SPComponentsModule,
    ChartsModule,
    MatTooltipModule,
    MatPaginatorModule,
    SocialLoginModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    RouterModule.forRoot(AppRoutes,{onSameUrlNavigation: 'reload'}),
    DataTablesModule,
    TradingModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleLogin
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              environment.facebookLogin,
              fbLoginOptions
            )
          }
        ],
        onError: (err) => {
          console.log("showing error");
          console.error(err);
          console.log(err.details);
          if (err.details.includes("Cookies"))
          {
            console.log("Cookies error");
            LoginComponent.isIncognito = true;
            //toastr.error("Google Login requires Cookies, which won't work in Incognito Mode. Please use without Incognito mode if you need to login with Google", "Google Login Warning", { timeOut: 30000 });
          }
        }
      } as SocialAuthServiceConfig,
    },
    DatePipe,
    LoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
