<div class="whatsappmessenger" *ngIf="visible">
  <a *ngIf="feedback" href="https://docs.google.com/forms/d/e/1FAIpQLSfF6Ua-OFBxxPxptUK7CJ8MpijD4v_Ycdu9HsDNkqdH_lVtEQ/viewform" target="_blank">
    <img src="assets/images/feedback.png" class="iconshadow">
  </a>
  <a href="https://api.whatsapp.com/send/?phone=60374902111" target="_blank">
    <img src="assets/images/icon_whatsapp.png" class="iconshadow">
  </a>
  <a href="http://m.me/beyondinsights" target="_blank" style="color:white;">

    <img src="assets/images/icon_fb.png" class="iconshadow">
  </a>
  <a href="mailto:support@beyondinsights.net" target="_blank" style="color:white;">
    <img src="assets/images/email.png" class="iconshadow">
  </a>
</div>
