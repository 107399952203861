import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { RequestHandlersService } from './request-handlers.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';

const endpoint: string = environment.apiUrl;
const nodeEndpoint: string = (environment.nodeapiUrl!='')?environment.nodeapiUrl:endpoint;
const stockEndpoint: string = environment.stockapiUrl;
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

/**
 * Class to handle API calls for all modules
 */
export class ApiService {

  constructor(
    private http: HttpClient,
    private requestHandler: RequestHandlersService) { }

  private dataObservable = new BehaviorSubject({});
  requestData = this.dataObservable.asObservable();

  /** ************************************************************************
   * Qcard Related API
   * *************************************************************************
   */

  // Get all workshops for the user
  public getUserEventGroups() {
    return this.http.post<any>(endpoint + 'student_event/get_event_groups', {}, httpOptions)
      .pipe(
        retry(3),catchError(this.requestHandler.handleHttpErrors
      ));
  }

  public getUserEventDetail(param) {
    return this.http.post<any>(endpoint + 'student_event/get_event_groups', {
      event_group_id: param.event_group_id,
    }, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  public confirmAttendance(param) {
    return this.http.put<any>(endpoint + 'student_event/confirm_attendance', {
      event_group_id: param.event_group_id,
    }, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  // Get all workshops for the user
  public getUserWorkshops() {
    return this.http.post<any>(endpoint + 'qcard/workshops', {}, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  // Get all threads for the workshop
  public getWorkshopThreads(param) {
    return this.http.post<any>(endpoint + 'qcard/threads', {
      event_group_id: param.event_group_id,
      last_retrieved_at: param.last_retrieved_at,
      only_me: 0
    }, httpOptions);
      //.pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  // Get all tags for workshop
  public getWorkshopTags(param) {
    return this.http.post<any>(endpoint + 'qcard/prepare_create', {
      event_group_id: param.event_group_id,
    }, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  // Create new thread
  public createNewThread(param) {
    return this.http.post<any>(endpoint + 'qcard/create', {
      event_group_id: param.event_group_id,
      question: param.question,
      category_id: param.category_id,
      follow_up:param.follow_up
      
    }, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  // Get thread details
  public getThreadDetails(param) {
    return this.http.post<any>(endpoint + 'qcard/view', {
      question_id: param.question_id
    }, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  // Close the qcard to disallow further questions
  public closeQuestion(param) {
    return this.http.post<any>(endpoint + 'qcard/close_question', {
      question_id: param.question_id
    }, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  // Add a comment to the question
  public addQuestionComment(param) {
    return this.http.post<any>(endpoint + 'qcard/add_question_comment', {
      question_id: param.question_id,
      comment: param.comment
    }, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  // Add a comment to the reply
  public addReplyComment(param) {
    return this.http.post<any>(endpoint + 'qcard/add_question_comment', {
      question_id: param.question_id,
      comment: param.comment
    }, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  // Edit the question
  public editQuestion(param) {
    return this.http.post<any>(endpoint + 'qcard/edit', {
      question_id: param.question_id,
      question: param.question,
      description: param.description
    }, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  // Delete the question's comment
  public deleteQuestionComment(param) {
    return this.http.post<any>(endpoint + 'qcard/delete_question_comment', {
      question_id: param.question_id,
      comment_id: param.comment_id
    }, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  // Delete the reply's comment
  public deleteReplyComment(param) {
    return this.http.post<any>(endpoint + 'qcard/delete_reply_comment', {
      question_id: param.question_id,
      reply_id: param.reply_id,
      comment_id: param.comment_id
    }, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  /** ************************************************************************
  * Location Related API
  * *************************************************************************
  */

  // Get the country listing
  public getCountryList() {
    return this.http.get<any>(endpoint + 'location/get_countries', {})
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  // Get the state listing for given country id
  public getStateList(param) {
    // const params = new HttpParams().set('country_id', param.country_id);
    return this.http.get<any>(endpoint + 'location/get_states/' + param.country_id)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  /** ************************************************************************
  * Profile Related API
  * *************************************************************************
  */

  // Get the profile details of current logged in student
  public getProfileDetails() {
    return this.http.post<any>(endpoint + 'personal/details', {}, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  public getBitsAccess() {
    return this.http.post<any>(endpoint + 'personal/bits_access', {}, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  // Update the profile details of current logged in student
  public updateProfileDetails(param) {
    return this.http.post<any>(endpoint + 'personal/update', {
      given_name: param.given_name,
      family_name: param.family_name,
      preferred_name: param.preferred_name,
      phone_number: param.phone_number,
      email: param.email,
      is_malaysian: param.is_malaysian,
      identification_number: param.identification_number,
      birth_date: param.birth_date,
      race: param.race,
      gender: param.gender,
      occupation: param.occupation,
      address: param.address,
      city: param.city,
      country_id: param.country_id,
      state_id: param.state_id,
      postcode: param.postcode,
    }, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  // Sends a request for profile update for given name, family name, phone number and email fields
  public requestProfileUpdate(param) {
    // Defining optional interface for requestable fields
    interface RequestFields {
      given_name?: string;
      family_name?: string;
      phone_number?: string;
      email?: string;
    }

    // Defining requested fields with passed parameter
    let requestedFields: RequestFields = param;

    return this.http.post<any>(endpoint + 'personal/request_profile_update', requestedFields, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  /** ************************************************************************
  * Reflections Related API
  * *************************************************************************
  */

  public getReflections() {
    return this.http.post<any>(endpoint + 'reflection/questions', {
      program_id: 6,
    }, httpOptions)
    .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
      //.pipe(catchError(this.requestHandler.handleHttpErrors));
  }

  public getReflectionsCoachData(param) {
    return this.http.post<any>(endpoint + 'reflection/coachdata', {
      program_id: 6,
      page_name: param.page_name,
      workshop_code: param.workshop_code
    }, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  public updateFeedback(param) {
    return this.http.post<any>(endpoint + 'reflection/submit_feedback', param, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }



  /**************************************************************************
   ****************         Event Calendar API       ************************
   **************************************************************************/


  // Get the state listing for given country id
  public getEventCalendar(param) {
    return this.http.get<any>(endpoint + 'event/calendar/' + param.year + '/' + param.month)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  /**************************************************************************
   ****************         Referral API       ************************
   **************************************************************************/

  public getReferralCampaigns(param) {
    return this.http.post<any>(endpoint + 'referral/campaigns', param, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  public getReferralReport(param) {
    return this.http.post<any>(endpoint + 'referral/report', param, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  /**************************************************************************
   ****************         Surveys API       ************************
   **************************************************************************/

   public getSurveys(param) {
    return this.http.post<any>(endpoint + 'surveys', param, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }
  
  /**************************************************************************
   ****************         Notification API       ************************
   **************************************************************************/


  // Get All Notification
  public getNotification(param) {
    return this.http.post<any>(endpoint + 'notification/all', {
      offset: param.offset,
      limit: param.limit
    }, httpOptions).pipe(
      retry(3),
      catchError(this.requestHandler.handleHttpErrors),

    );
  }
  // User Clicks Notification
  public clickNotification(param) {
    return this.http.post<any>(endpoint + 'notification/click', {
      notification_id: param.id
    }, httpOptions).pipe(
      retry(3),
      catchError(this.requestHandler.handleHttpErrors)
    );
  }
  // Delete Notification
  public deleteNotification(param) {
    return this.http.post<any>(endpoint + 'notification/delete', {
      notification_id: param.id
    }, httpOptions).pipe(
      retry(3),
      catchError(this.requestHandler.handleHttpErrors)
    );
  }
  // Count Notification
  public countNotification() {
    return this.http.post<any>(endpoint + 'notification/count', {
    }, httpOptions).pipe(
      retry(3),
      catchError(this.requestHandler.handleHttpErrors)

    );
  }

  /**************************************************************************
   ****************         Stock Market API       ************************
   **************************************************************************/

  // Get the state listing for given country id
  public getStockAutocomplete(param) {
    return this.http.post<any>(stockEndpoint + 'apiStockAutoComplete', param, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  public getStockTimeMarks(param) {
    return this.http.post<any>(stockEndpoint + 'apigetTimeMarks', param, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  // Get the state listing for given country id
  public getStockData(param) {
    return this.http.post<any>(stockEndpoint + 'apiStockData', param, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  // Get the state listing for given country id
  public getStockList(param) {
    return this.http.post<any>(stockEndpoint + 'apiStockList', param, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  public getCheckoutSession(param) {
    return this.http.post<any>(stockEndpoint + 'apiStripeCreateCheckout' ,param, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  public getCheckSubscription(param) {
    return this.http.post<any>(stockEndpoint + 'apiCheckSubscription' ,param, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  public getCustomerPortal(param) {
    return this.http.post<any>(stockEndpoint + 'apiStripeCreateCustomerPortal' ,param, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  public getEOD(param) {
    return this.http.post<any>(stockEndpoint + 'apiGetEOD' ,param, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  public getRequestVideo(param) {
    return this.http.post<any>(stockEndpoint + 'apiRequestVideo' ,param, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  public getVDOList(param) {
    return this.http.post<any>(stockEndpoint + 'apiListVDO' ,param, httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  public getFAQ(param) {
    return this.http.post<any>(stockEndpoint + 'apiFAQs' ,param,httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  public getGlossary(param) {
    return this.http.post<any>(stockEndpoint + 'apiGlossaries' ,param,httpOptions)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }

  public getAbbreviation(param) {
    return this.http.post<any>(stockEndpoint + 'apiAbbreviations' ,param)
      .pipe(retry(3),catchError(this.requestHandler.handleHttpErrors));
  }
}
