import { ApiService } from '../../../services/api.service';
import { Component, OnInit, OnDestroy,Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { timer,Subscription } from 'rxjs';


@Component({
  selector: 'app-notifsidebar',
  templateUrl: './notifsidebar.component.html',
  styleUrls: ['./notifsidebar.component.scss']
})

export class NotifsidebarComponent implements OnInit {
  @Input() count: number;
  @Output() countChanged: EventEmitter<number> =   new EventEmitter();
  @Output() toClose: EventEmitter<boolean> =   new EventEmitter();
  notificationArray: any = [];
  isDelete = false;
  checktimer:any;
  subscriptionTimer:Subscription|null = null;

  constructor(private apiService: ApiService,private router: Router) { }

  ngOnInit(): void {
    // this.checktimer = timer(1000, 5000);
    // this.subscriptionTimer = this.checktimer.subscribe(val => this.getNotification());    
  }

  ngOnDestroy():void {
    if (this.subscriptionTimer!=null) this.subscriptionTimer.unsubscribe();
  }



  getNotification() {
    let param = {
      offset: 0,
      limit: 999
    }
    this.apiService.getNotification(param).subscribe(data => {
      this.notificationArray = data.result;
    });
    this.apiService.countNotification().subscribe(data => { 
      this.count = data.result;
      this.countChanged.emit(this.count);
    });    
  }
  clickNotification(param) {
    if (!this.isDelete)
    {
      this.router.navigate(['/',param.navi_module,param.navi_sub_module,param.navi_param_1]).then(() => {
        window.location.reload();
      });
      this.toClose.emit(true);     
    }    
  }
  deleteNotification(param) {
    console.log("delete");
    this.isDelete = true;        
    this.apiService.deleteNotification(param).subscribe(data => {      
      this.isDelete = false; 
      this.getNotification()   
    });    
  }
}
