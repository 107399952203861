<!DOCTYPE html>
<html lang="en">

<head>
    <link href="first-login-wizard.component.scss" rel="stylesheet" />
</head>

<body>

    <div class="first-time-title center-content">
        <div class="center-content">
            <img src="assets/images/logo.png" class="logo">
        </div>
    </div>

    <div>
        <mat-card class="first-time-card">
            <mat-card-content>
                <h4>As this is your first login, please check if your profile information is accurate and update
                    accordingly if needed.</h4>
                <mat-vertical-stepper [linear]="isLinear" #stepper>
                    <mat-step [stepControl]="firstFormGroup">
                        <form [formGroup]="firstFormGroup">
                            <div>
                                <ng-template matStepLabel>Name</ng-template>
                                <mat-form-field class="half-width-input">
                                    <mat-label>First Name / Given Name</mat-label>
                                    <input matInput formControlName="givenName"
                                        (change)="getRequestedFieldsArray('givenName', 'GivenName', 'given Name')"
                                        readonly>
                                    <mat-error *ngIf="firstFormGroup.controls.givenName.hasError('required')">
                                        First name is required
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="half-width-input">
                                    <mat-label>Last Name / Family Name</mat-label>
                                    <input matInput formControlName="familyName"
                                        (change)="getRequestedFieldsArray('familyName', 'FamilyName', 'family Name')"
                                        readonly>
                                    <mat-error *ngIf="firstFormGroup.controls.familyName.hasError('required')">
                                        Last name is required
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="half-width-input">
                                    <mat-label>Preferred Name</mat-label>
                                    <input matInput formControlName="preferredName" disabled>
                                    <mat-error *ngIf="firstFormGroup.controls.preferredName.hasError('required')">
                                        Preferred name is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div>
                                <button mat-raised-button class="next-button" matStepperNext>Next</button>
                            </div>
                        </form>
                    </mat-step>

                    <!-- Contact information group -->
                    <mat-step [stepControl]="secondFormGroup">
                        <form [formGroup]="secondFormGroup">
                            <div>
                                <ng-template matStepLabel>Contact Information</ng-template>
                                <mat-form-field class="half-width-input">
                                    <mat-label>Mobile Phone Number</mat-label>
                                    <input matInput formControlName="phoneNumber"
                                        (change)="getRequestedFieldsArray('phoneNumber', 'PhoneNumber', 'phone Number')"
                                        required>
                                    <mat-error *ngIf="secondFormGroup.controls.phoneNumber.hasError('pattern') &&
                                    !secondFormGroup.controls.phoneNumber.hasError('required')">
                                        Please enter a valid mobile phone number without any symbols
                                    </mat-error>
                                    <mat-error *ngIf="secondFormGroup.controls.phoneNumber.hasError('required')">
                                        Mobile phone number is required
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="half-width-input">
                                    <input matInput placeholder="Email" formControlName="email"
                                        (change)="getRequestedFieldsArray('email', 'Email', 'email')" type="email" name="email"
                                        required>
                                    <mat-error *ngIf="secondFormGroup.controls.email.hasError('pattern') &&
                                    !secondFormGroup.controls.email.hasError('required')">
                                        Please enter a valid email address
                                    </mat-error>
                                    <mat-error *ngIf="secondFormGroup.controls.email.hasError('required')">
                                        Email is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div>
                                <button mat-raised-button class="next-button" matStepperNext>Next</button>
                                <button mat-raised-button class="back-button" matStepperPrevious>Back</button>
                            </div>
                        </form>
                    </mat-step>

                    <!-- Personal information group -->
                    <mat-step [stepControl]="thirdFormGroup">
                        <form [formGroup]="thirdFormGroup">
                            <div>
                                <ng-template matStepLabel>Personal Information</ng-template>
                                <!--<div>
                                    <h4>Identity Document Type</h4>
                                    <div>
                                        <mat-radio-group aria-label="Select an option"
                                            formControlName="identityDocumentType">
                                            <mat-radio-button value="nric" (click)="disablePassportValidation()"
                                                required>Malaysia NRIC</mat-radio-button>
                                            <mat-radio-button value="passport" (click)="disableNRICValidation()"
                                                required>Passport/Other Identification
                                            </mat-radio-button>
                                        </mat-radio-group>
                                        <mat-error
                                            *ngIf="thirdFormGroup.controls.identityDocumentType.hasError('required')">
                                            Please select an identity type
                                        </mat-error>
                                    </div>
                                    <mat-form-field class="nric-first center-text"
                                        *ngIf="thirdFormGroup.controls.identityDocumentType.value === 'nric'">
                                        <input matInput placeholder="NRIC Number" formControlName="nricNumberFirst"
                                            name="nricNumberFirst" maxlength="6" required>
                                    </mat-form-field>

                                    <mat-form-field class="nric-second center-text"
                                        *ngIf="thirdFormGroup.controls.identityDocumentType.value === 'nric'">
                                        <input matInput formControlName="nricNumberSecond" name="nricNumberSecond"
                                            maxlength="2" required>
                                    </mat-form-field>

                                    <mat-form-field class="nric-third center-text"
                                        *ngIf="thirdFormGroup.controls.identityDocumentType.value === 'nric'">
                                        <input matInput formControlName="nricNumberThird" name="nricNumberThird"
                                            maxlength="4" required>
                                    </mat-form-field>
                                    <mat-error *ngIf="thirdFormGroup.controls.nricNumberSecond.hasError('required')||thirdFormGroup.controls.nricNumberThird.hasError('required')||thirdFormGroup.controls.nricNumberFirst.hasError('required')">
                                        NRIC number is required
                                    </mat-error>
                                    <mat-error *ngIf="thirdFormGroup.controls.nricNumberSecond.hasError('pattern')||thirdFormGroup.controls.nricNumberThird.hasError('pattern')||thirdFormGroup.controls.nricNumberFirst.hasError('pattern')">
                                        Please enter a valid NRIC number. If you're foreigner, please select Passport at the top
                                    </mat-error>

                                    <mat-form-field class="half-width-input center-text"
                                        *ngIf="thirdFormGroup.controls.identityDocumentType.value === 'passport'">
                                        <input matInput placeholder="Passport Number" formControlName="passportNumber"
                                            name="passportNumber" maxlength="9" required>
                                    </mat-form-field>
                                    <mat-error *ngIf="thirdFormGroup.controls.passportNumber.hasError('required')">
                                        Passport number is required
                                    </mat-error>
                                    <mat-error *ngIf="thirdFormGroup.controls.passportNumber.hasError('pattern')">
                                        Please enter a valid passport number. If you're a Malaysian, select Malaysia NRIC at the top
                                    </mat-error>
                                </div>-->

                                <mat-form-field class="birth-date-input">
                                    <mat-label>Date of Birth</mat-label>
                                    <input matInput [matDatepicker]="picker" formControlName="dateOfBirth"
                                        [value]="dateOfBirth.value" disabled>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker [disabled]="false"></mat-datepicker>
                                </mat-form-field>

                                <div style="padding-bottom:1rem;"> <!--class="tw-pb-4"-->
                                    <div>
                                    <mat-label  style="font-size:0.75rem;line-height:1rem;color:rgba(0, 0, 0, 0.54);">Gender</mat-label> <!--class="tw-text-xs tw-text-gray-400"-->
                                    </div>

                                      <mat-radio-group aria-label="Select an option" formControlName="gender">
                                          <mat-radio-button value="M" required>Male</mat-radio-button>
                                          <mat-radio-button value="F" required>Female</mat-radio-button>
                                      </mat-radio-group>
                                      <mat-error *ngIf="thirdFormGroup.controls.gender.hasError('required')">
                                          Please select a gender
                                      </mat-error>
                                </div>

                                <!--<mat-form-field class="half-width-input">
                                    <mat-label>Race</mat-label>
                                    <input matInput placeholder="Race" formControlName="race" name="race" required>
                                    <mat-error *ngIf="thirdFormGroup.controls.race.hasError('required')">
                                        Race is required
                                    </mat-error>
                                    <mat-error *ngIf="thirdFormGroup.controls.race.hasError('pattern')">
                                        Only text is allowed for race
                                    </mat-error>
                                </mat-form-field>-->

                                <mat-form-field class="half-width-input">
                                    <mat-label>Occupation</mat-label>
                                    <input matInput placeholder="Occupation" formControlName="occupation"
                                        name="occupation" required>
                                    <mat-error *ngIf="thirdFormGroup.controls.occupation.hasError('required')">
                                        Occupation is required
                                    </mat-error>
                                    <!--<mat-error *ngIf="thirdFormGroup.controls.occupation.hasError('pattern')">
                                        Occupation field only allows space and alphabets only
                                    </mat-error>-->
                                </mat-form-field>
                            </div>
                            <div>
                                <button mat-raised-button class="next-button" matStepperNext>Next</button>
                                <button mat-raised-button class="back-button" matStepperPrevious>Back</button>
                            </div>
                        </form>
                    </mat-step>

                    <!-- Mailing Address group -->
                    <mat-step [stepControl]="fourthFormGroup">
                        <form [formGroup]="fourthFormGroup">
                            <div>
                                <ng-template matStepLabel>Mailing Address</ng-template>
                                <mat-form-field class="address-input">
                                    <mat-label>Address</mat-label>
                                    <input matInput formControlName="address" required>
                                    <mat-error *ngIf="fourthFormGroup.controls.address.hasError('required')">
                                        Address is required
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="half-width-input">
                                    <mat-label>City</mat-label>
                                    <input matInput formControlName="city" required>
                                    <mat-error *ngIf="fourthFormGroup.controls.city.hasError('required')">
                                        City is required
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="half-width-input">
                                    <mat-label>Post Code</mat-label>
                                    <input matInput formControlName="postcode" required>
                                    <mat-error *ngIf="fourthFormGroup.controls.postcode.hasError('required')">
                                        Post code is required
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="half-width-input" appearance="fill">
                                    <mat-label>Country</mat-label>
                                    <mat-select formControlName="selectedCountry">
                                        <mat-option *ngFor="let country of countries" [value]="country.id"
                                            (click)="getStates(country.id)">
                                            {{ country.name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="fourthFormGroup.controls.selectedCountry.hasError('required')">
                                        Please select a country
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="half-width-input" appearance="fill">
                                    <mat-label>State</mat-label>
                                    <mat-select formControlName="selectedState"
                                        [disabled]="!fourthFormGroup.controls.selectedCountry.value">
                                        <mat-option *ngFor="let state of states" [value]="state.id">
                                            {{ state.name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="fourthFormGroup.controls.selectedState.hasError('required')">
                                        Please select a state
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div>
                                <button mat-raised-button class="next-button" matStepperNext>Next</button>
                                <button mat-raised-button class="back-button" matStepperPrevious>Back</button>
                            </div>
                        </form>
                    </mat-step>

                    <!-- Social Media Connection group -->
                    <!--<mat-step>
                        <div class="social-media-section">
                            <ng-template matStepLabel>Social Media Connection</ng-template>
                            <div>
                                <button class="btn btn-facebook" (click)="connectToFacebook()" *ngIf="!facebookConnected">
                                    Connect to Facebook
                                </button>
                                <div class="social-connected-section" *ngIf="!!facebookConnected">
                                    You have already connected your account to Facebook.
                                </div>
                            </div>
                        </div>
                        <div>
                            <button mat-raised-button class="next-button" matStepperNext>Next</button>
                            <button mat-raised-button class="back-button" matStepperPrevious>Back</button>
                        </div>
                    </mat-step>-->

                    <mat-step>
                        <ng-template matStepLabel>Done</ng-template>
                        <div class="security-measure-section" *ngIf="!!requestedFields.length">
                            As a security measure, these fields will require approval (within 2 working days):
                            <ul>
                                <li *ngFor="let requestedField of requestedFields">{{ requestedField | titlecase }}
                                </li>
                            </ul>
                        </div>
                        <p>Re-login to student portal</p>
                        <div>
                            <button mat-raised-button class="next-button"
                                (click)="confirmUpdateProfileInfo()">Re-login</button>
                            <button mat-raised-button class="back-button" matStepperPrevious>Back</button>
                        </div>
                    </mat-step>
                </mat-vertical-stepper>

            </mat-card-content>

            <mat-card-content>
                <div>
                    <b>Legal and policies</b>
                </div>
                <div>
                    <a href="https://www.beyondinsights.net/terms-of-service/">Term of Use</a> <!--|
                    <a href="#">Data Policy</a> |
                    <a href="#">Cookie Policy</a>-->
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</body>

</html>
