import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appFileDragAndDrop]',
})
export class FileDragAndDropDirective {
  @Output()
  enterDropZone = new EventEmitter();

  @Output()
  leaveDropZone = new EventEmitter();

  @Output()
  dropFile = new EventEmitter<FileList | undefined>();

  constructor(private readonly elementRef: ElementRef) {}

  @HostListener('dragenter', ['$event'])
  onDragEnter(dragEvent: DragEvent) {
    dragEvent.preventDefault();
    dragEvent.stopPropagation();

    this.enterDropZone.emit();
  }

  @HostListener('dragover', ['$event'])
  onDragOver(dragEvent: DragEvent) {
    dragEvent.preventDefault();
    dragEvent.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(dragEvent: DragEvent) {
    dragEvent.preventDefault();
    dragEvent.stopPropagation();

    if (!this.elementRef.nativeElement.contains(dragEvent.relatedTarget)) {
      this.leaveDropZone.emit();
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(dragEvent: DragEvent) {
    dragEvent.preventDefault();
    dragEvent.stopPropagation();

    this.leaveDropZone.emit();
    this.dropFile.emit(dragEvent.dataTransfer?.files);
  }
}
