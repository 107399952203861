import { Component, OnInit,Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-onboard-popup',
  templateUrl: './onboard-popup.component.html',
  styleUrls: ['./onboard-popup.component.scss']
})
export class OnboardPopupComponent implements OnInit {

  @Input() url="";
  @Input() title="Guide";
  sanitizedUrl:any;

  constructor(public activeModal: NgbActiveModal,public sanitizer:DomSanitizer  ) {     

  }

  ngOnInit(): void {    
    this.sanitizedUrl = this.videoURL();    
  }

  videoURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
