import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export interface GlossaryItem {
  Question: string;
  Answer: string;
}

const STOCK_DETAILS_EPS:any =
[
  {
    "Question": "ALLOCATION",
    "Answer": "The assignment and reassignment of a cost or group of costs to one or more cost objectives based on a reasonable standard.  Terms with assorted shades of meaning are cost reallocation",

  },
  {
    "Question": "APPROVED BUDGET",
    "Answer": "The Approved Budget represents the Board approved financial plan for a given year.  The Approved Budget serves as the benchmark by which the Society's financial performance is implemented and monitored.",

  },
  {
    "Question": "ASSESSMENT",
    "Answer": "A method by which indirect costs are spread to an operating department based on a predetermined basis (see Indirect Assessment).",

  },
  {
    "Question": "ASSET",
    "Answer": "Assets are probable future economic benefits obtained or controlled by the Society as a result of past transactions or events.",

  },
  {
    "Question": "BOOK VALUE",
    "Answer": "Original cost of an asset less any accumulated depreciation.  The book value is generally not equal to the market value of an asset (see Depreciation Expense).",

  },
  {
    "Question": "BUDGET",
    "Answer": "A quantitative expression of a plan of action",

  },
  {
    "Question": "CAPITAL ASSET",
    "Answer": "Also called fixed asset. A physical asset with a useful life of more than one year and land.",

  },
  {
    "Question": "CAPITAL BUDGET",
    "Answer": "A process of planning expenditures for assets with returns expected to continue beyond one year or the making of long",
    "": "term planning decisions for investments in fixed assets and their associated financing."
  },
  {
    "Question": "CASH AND CASH EQUIVALENTS",
    "Answer": "Cash equivalents include money market funds which can be liquidated on a daily basis.  These money market funds invest primarily in short term U.S. Treasury securities",

  },
  {
    "Question": "COST",
    "Answer": "Resources sacrificed or forgone to achieve a specific objective.",

  },
  {
    "Question": "COST OF CAPITAL",
    "Answer": "The theoretical cost of obtaining external financing for capital expenditures.",

  },
  {
    "Question": "CURRENT ASSET",
    "Answer": "Cash and other assets that are reasonably expected to be turned into cash receivables and prepaid expenses.",

  },
  {
    "Question": "CURRENT LIABILITY",
    "Answer": "Short term liabilities whose liquidation is reasonably expected to require the use of existing resources classified as current assets",

  },
  {
    "Question": "CURRENT RATIO",
    "Answer": "A ratio that provides an indication of whether the organization has sufficient liquid assets to meet its short term obligations as they come due and is defined as the ratio of current assets to current liabilities.",

  },
  {
    "Question": "DEBT RATIO",
    "Answer": "A ratio used to assess the organization’s level of",

  },
  {
    "Question": "DEFERRED REVENUE",
    "Answer": "A liability account representing revenue billed in advance or cash collected in advance of when the entity transfers promised goods and services to the customer.  When this occurs",

  },
  {
    "Question": "DEPARTMENT ID",
    "Answer": "This is a level at which financial data is captured.  A Department ID may be a publication",

  },
  {
    "Question": "DEPRECIATION EXPENSE",
    "Answer": "The allocation of the cost of a fixed asset (less salvage value) over its estimated useful life in a systematic and rational manner.  This systematic and rational allocation provides for a reasonable and consistent method for recognizing and matching the cost of an asset against the revenues generated during the asset's useful life.",

  },
  {
    "Question": "DIRECT COSTS",
    "Answer": "Costs that can be specifically associated with a single cost objective in an economically feasible way or closely associated with the output of a product or service.  In addition",

  },
  {
    "Question": "DUES ESCALATOR",
    "Answer": "Amount by which the annual membership dues rate is increased.  The escalator is subject to certain limitations",

  },
  {
    "Question": "DUES POOL",
    "Answer": "Consolidation of ACS member dues revenues",

  },
  {
    "Question": "EXPENSES",
    "Answer": "Expenses are outflows or other uses of assets or incurrences of liabilities (or a combination of both) resulting from the delivery or production of goods",

  },
  {
    "Question": "FIXED COSTS",
    "Answer": "Costs that remain unchanged within the relevant range for a given time period despite fluctuations in activity.",

  },
  {
    "Question": "FREE CASH FLOW",
    "Answer": "An indication of the amount of cash being generated by the operations of an organization less expenditures for capital purchases.",

  },
  {
    "Question": "FUND BALANCE RATIO",
    "Answer": "This ratio provides a rough test of the adequacy of the Society’s unrestricted net assets.  The ratio compares the current year’s unrestricted net assets with next year’s operating expenses.",

  },
  {
    "Question": "GAAP",
    "Answer": "Generally Accepted Accounting Principles refers to the standard framework of conventions",

  },
  {
    "Question": "GENERAL AND ADMINISTRATIVE EXPENSES (GAE)",
    "Answer": "Those expenses associated with the indirect cost centers; e.g.",

  },
  {
    "Question": "GROSS MARGIN",
    "Answer": "Sales revenue less the direct cost of producing goods or services sold.",

  },
  {
    "Question": "INCREMENTAL COST",
    "Answer": "The difference in total cost between two alternatives in a decision",
    "": "also called differential cost."
  },
  {
    "Question": "INDIRECT ASSESSMENT",
    "Answer": "General and administrative expenses and building costs which are distributed to the operating units through an assessment formula.",

  },
  {
    "Question": "INDIRECT COSTS",
    "Answer": "Costs that are not directly related to the output of a product or service or cannot be identified specifically with a single cost objective in an economically feasible manner.  Indirect expenses represent the costs of running a business.",

  },
  {
    "Question": "INTERNAL RATE OF RETURN",
    "Answer": "The rate of interest at which the present value of expected cash inflows from a project equals the present value of the expected cash outflows of the project.",

  },
  {
    "Question": "IRS FORM 990",
    "Answer": "An annual information return",

  },
  {
    "Question": "IRS FORM 990",
    "Answer": "An income tax return Exempt Organization Business Income Tax Return",

  },
  {
    "Question": "LIABILITY",
    "Answer": "Liabilities are probable future sacrifices of economic benefits arising from present or past obligations of the Society.",

  },
  {
    "Question": "LIQUID ASSETS",
    "Answer": "Economic resources that can be immediately convertible to cash (generally without incurring a loss).  The Society defines liquid assets as cash and cash equivalents",

  },
  {
    "Question": "MANAGEMENT REPORTS (MRs)",
    "Answer": "Internal financial reports that compare actual performance to budgeted performance by type of revenue and expense.",

  },
  {
    "Question": "NET ASSETS",
    "Answer": "Represents the residual interest in the assets of the Society that remains after deducting its liabilities.  Also called reserves.",

  },
  {
    "Question": "NET ASSETS WITHOUT DONOR RESTRICTIONS",
    "Answer": "Represents net assets that are free of donor imposed restrictions. All revenues",

  },
  {
    "Question": "NET ASSETS WITH DONOR RESTRICTIONS",
    "Answer": "Represents net assets that are subject to donor imposed restrictions that will be met either by actions of the Society or the passage of time. These net assets include donor restricted endowments and unconditional pledges.  Generally",

  },
  {
    "Question": "OPERATING BUDGET",
    "Answer": "A document and a process of planning operating revenues and expenditures for a year or series of years.",

  },
  {
    "Question": "OPPORTUNITY COST",
    "Answer": "The investment return forgone by using liquid resources for a particular acquisition or project.",

  }
];



@Injectable()
export class Faqglossary {
  getFaqGlossary(): GlossaryItem[] {
    return STOCK_DETAILS_EPS;
  }
}
