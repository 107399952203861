import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';


export interface Menu {
  state: string,
  name: string,
  type: string,
  icon: string,
  beta?:boolean,
}

const MENUITEMS = [
  { state: 'events', name: 'My Events', type: 'link', icon: 'calendar_today' },
  { state: 'reflection', name: 'MTB Reflections', type: 'link', icon: 'psychology' },
  { state: 'surveys', name: 'Surveys', type: 'link', icon: 'poll' },
  {
    state: '#', name: 'BiTS', type: 'accordion', icon: 'money', beta:true,
    subMenuitems: [
    { state: 'investors/trader-nlv-setting', name: 'NLV Settings', type: 'link', icon: 'settings', beta:false },
    { state: 'investors/trader-plan-summary', name: 'Portfolio', type: 'link', icon: 'trending_up', beta:false },
    { state: 'investors/trader', name: 'Trading Plan', type: 'link', icon: 'playlist_add', beta:false },
    { state: 'investors/trader-log', name: 'Trade Log', type: 'link', icon: 'checklist', beta:false },
    { state: 'investors/trader-statistic', name: 'Trade Statistic', type: 'link', icon: 'analytics', beta:false },
    { state: 'investors/trader-journal', name: 'Trade Journal', type: 'link', icon: 'app_registration', beta:false },
    { state: '#', name: 'Full Guide', type: 'help', icon: 'help', beta:false,video_url:"https://player.vimeo.com/video/751531560?h=c81d63194f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" },
    ]
  },
];

const MENUITEMS_TEST = [
  { state: 'events', name: 'My Events', type: 'link', icon: 'calendar_today' },
  { state: 'events/calendar', name: 'Calendar', type: 'link', icon: 'calendar_view_month' },
  { state: 'surveys', name: 'Surveys', type: 'link', icon: 'poll' },
  //{ state: 'investors', name: 'Investor Dashboard (W)', type: 'link', icon: 'construction' },
  //{ state: 'investors/screener', name: 'Screener (Test)', type: 'link', icon: 'fact_check' },
  //{ state: 'investors/trader-plan-summary', name: 'Portfoliio', type: 'link', icon: 'construction' },
  //{ state: 'investors/trader', name: 'Trading Plan', type: 'link', icon: 'construction' },
  //{ state: 'investors/tdameritrade', name: 'TDameritrade (Test)', type: 'link', icon: 'candlestick_chart' },
  // { state: 'category3', name: 'FAQ', type: 'link', icon: 'question_answer' },
  // { state: 'category4', name: 'Resource Library', type: 'link', icon: 'work' },
  //{ state: 'investors', name: 'Investing', type: 'link', icon: 'construction', beta:true },
  //{ state: 'investors/screener', name: 'Screener (Test)', type: 'link', icon: 'fact_check' },
  {
    state: '#', name: 'BiTS', type: 'accordion', icon: 'money', beta:true,
    subMenuitems: [
    { state: 'investors/trader-nlv-setting', name: 'NLV Settings', type: 'link', icon: 'settings', beta:false },
    { state: 'investors/trader-plan-summary', name: 'Portfolio', type: 'link', icon: 'trending_up', beta:false },
    { state: 'investors/trader', name: 'Trading Plan', type: 'link', icon: 'playlist_add', beta:false },
    { state: 'investors/trader-log', name: 'Trade Log', type: 'link', icon: 'checklist', beta:false },
    { state: 'investors/trader-statistic', name: 'Trade Statistic', type: 'link', icon: 'analytics', beta:false },
    { state: 'investors/trader-journal', name: 'Trade Journal', type: 'link', icon: 'app_registration', beta:false },
    { state: '#', name: 'Full Guide', type: 'help', icon: 'help', beta:false,video_url:"https://player.vimeo.com/video/751531560?h=c81d63194f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" },
    ]
  },
  { state: 'reflection', name: 'MTB Reflections', type: 'link', icon: 'psychology' },
];

const MENUITEMS_STAGING = [
  // { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer' },
  // { state: 'qcard', name: 'Today\'s Session', type: 'link', icon: 'class' },
  { state: 'events', name: 'My Events', type: 'link', icon: 'calendar_today' },
  { state: 'events/calendar', name: 'Calendar', type: 'link', icon: 'calendar_view_month' },
  { state: 'investors', name: 'Investing', type: 'link', icon: 'construction' },
  //{ state: 'investors/screener', name: 'Screener (Test)', type: 'link', icon: 'fact_check' },
  {
    state: '#', name: 'BiTS', type: 'accordion', icon: 'money', beta:true,open:false,
    subMenuitems: [
    { state: 'investors/trader-nlv-setting', name: 'NLV Settings', type: 'link', icon: 'settings', beta:false },
    { state: 'investors/trader-plan-summary', name: 'Portfolio', type: 'link', icon: 'trending_up', beta:false },
    { state: 'investors/trader', name: 'Trading Plan', type: 'link', icon: 'playlist_add', beta:false },
    { state: 'investors/trader-log', name: 'Trade Log', type: 'link', icon: 'checklist', beta:false },
    { state: 'investors/trader-statistic', name: 'Trade Statistic', type: 'link', icon: 'analytics', beta:false },
    { state: 'investors/trader-journal', name: 'Trade Journal', type: 'link', icon: 'app_registration', beta:false },
    { state: '#', name: 'Full Guide', type: 'help', icon: 'help', beta:false,video_url:"https://player.vimeo.com/video/751531560?h=c81d63194f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" },
    ]
  },

  {
    state: '#', name: 'Coach', type: 'accordion', icon: 'admin_panel_settings', beta:true,
    subMenuitems: [

    { state: 'coach/q-card', name: 'Q-card', type: 'link', icon: 'question_answer', beta:true },

    ]
  },
  //{ state: 'investors/tdameritrade', name: 'TDameritrade (Test)', type: 'link', icon: 'candlestick_chart' },
  // { state: 'category3', name: 'FAQ', type: 'link', icon: 'question_answer' },
  // { state: 'category4', name: 'Resource Library', type: 'link', icon: 'work' },
  { state: 'reflection', name: 'MTB Reflections', type: 'link', icon: 'psychology' },
  { state: 'surveys', name: 'Surveys', type: 'link', icon: 'poll' },
  { state: 'events/class', name: 'Class', type: 'link', icon: 'construction' },

  {
    state: '#',
    name: 'Investor Dashboard',
    beta: true,
    icon: 'dashboard',
    type: 'accordion',
    subMenuitems: [
      {
        state: 'investors/watchlist',
        name: 'Watchlist',
        icon: 'notes',
        type: 'link',
      },
      {
        state: 'investors/watchlist/admin',
        name: 'Watchlist Editor',
        icon: 'edit_note',
        type: 'link',
      },
    ],
  },
];

const RIGHTSIDEBARITEMS = [
  {
    state: '#', name: 'Profile', type: 'accordion', icon: 'account_circle',
    subMenuitem: [
      {
        state: 'settings',
        childstate: 'personal-info',
        name: 'Personal Information',
        icon: ''
      },
      {
        state: 'settings',
        childstate: 'security',
        name: 'Security & Privacy',
        icon: ''
      },

    ]
  },
  {
    state: '#', name: 'Referral', type: 'accordion', icon: 'loyalty',
    subMenuitem: [
      {
        state: 'settings',
        childstate: 'referral',
        name: 'Link & Campaigns',
        icon: ''
      },
      {
        state: 'settings',
        childstate: 'referral-report',
        name: 'Report',
        icon: ''
      },
    ]
  }
]


const RIGHTSIDEBARITEMS_STAGING = [
  {
    state: '#', name: 'Profile', type: 'accordion', icon: 'account_circle',
    subMenuitem: [
      {
        state: 'settings',
        childstate: 'personal-info',
        name: 'Personal Information',
        icon: ''
      },
      {
        state: 'settings',
        childstate: 'security',
        name: 'Security & Privacy',
        icon: ''
      },

    ]
  },
  {
    state: '#', name: 'Subscription', type: 'accordion', icon: 'subscriptions',
    subMenuitem: [
      {
        state: 'settings',
        childstate: 'subscription-settings',
        name: 'Subscription Settings',
        icon: ''
      },
    ]
  },
  /*{
    state:'settings/referral', name: 'Referral', type: 'link', icon: 'loyalty',
  },*/
  {
    state: '#', name: 'Referral', type: 'accordion', icon: 'loyalty',
    subMenuitem: [
      {
        state: 'settings',
        childstate: 'referral',
        name: 'Link & Campaigns',
        icon: ''
      },
      {
        state: 'settings',
        childstate: 'referral-report',
        name: 'Report',
        icon: ''
      },
    ]
  },
  /*{
    state: '#', name: 'Trader', type: 'accordion', icon: 'account_balance',
    subMenuitem: [
      // {
      //   state: 'settings',
      //   childstate: 'subscription-settings',
      //   name: 'General',
      //   icon: ''
      // },
      {
        state: 'investors',
        childstate:'trader-setting',
        param:'1',
        name: 'Investing',
        icon: ''
      },
      {
        state: 'investors',
        childstate: 'trader-setting',
        param:'2',
        name: 'Swing Trading',
        icon: ''
      },
      {
        state: 'investors',
        childstate: 'trader-setting',
        param:'3',
        name: 'Trend Trading',
        icon: ''
      },
    ]
  },*/

//},
  // {
  //   state: '#', name: 'Subscription', type: 'accordion', icon: 'subscriptions',
  //   subMenuitem: [
  //     {
  //       state: '#',
  //       name: 'Sub Category 1',
  //       icon: ''
  //     },
  //     {
  //       state: '#',
  //       name: 'Sub Category 2',
  //       icon: ''
  //     },
  //     {
  //       state: '#',
  //       name: 'Sub Category 3',
  //       icon: ''
  //     },
  //   ]
  // },
  // {
  //   state: '#', name: 'Referral Program', type: 'accordion', icon: 'groups',
  //   subMenuitem: [
  //     {
  //       state: '#',
  //       name: 'Sub Category 1',
  //       icon: ''
  //     },
  //     {
  //       state: '#',
  //       name: 'Sub Category 2',
  //       icon: ''
  //     },
  //     {
  //       state: '#',
  //       name: 'Sub Category 3',
  //       icon: ''
  //     },
  //   ]
  // },
  // {
  //   state: '#', name: 'History', type: 'accordion', icon: 'history',
  //   subMenuitem: [
  //     {
  //       state: '#',
  //       name: 'Sub Category 1',
  //       icon: ''
  //     },
  //     {
  //       state: '#',
  //       name: 'Sub Category 2',
  //       icon: ''
  //     },
  //     {
  //       state: '#',
  //       name: 'Sub Category 3',
  //       icon: ''
  //     },
  //   ]
  // },
  // {
  //   state: '#', name: 'Certification', type: 'accordion', icon: 'document_scanner',
  //   subMenuitem: [
  //     {
  //       state: '#',
  //       name: 'Sub Category 1',
  //       icon: ''
  //     },
  //     {
  //       state: '#',
  //       name: 'Sub Category 2',
  //       icon: ''
  //     },
  //     {
  //       state: '#',
  //       name: 'Sub Category 3',
  //       icon: ''
  //     },
  //   ]
  // },
]

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    if (environment.production)
      return MENUITEMS;
    else if (environment.testing)
      return MENUITEMS_TEST;

    return MENUITEMS_STAGING;
  }
  getRightMenuitem(): Menu[] {
    if (environment.production)
      return RIGHTSIDEBARITEMS;
    else if (environment.testing)
      return RIGHTSIDEBARITEMS_STAGING;

    return RIGHTSIDEBARITEMS_STAGING;
  }
}
