import { Injectable } from '@angular/core';

export interface StockDetail {
    name: string;
    value: string;        
    param:string;    
  }

  
const STOCK_DETAILS_GENERAL:any = 
  [
    {
      "name":"Fiscal Year End",
      "value":"June",
      "param":"General_FiscalYearEnd"
    },
    {
      "name":"Currency Code",
      "value":"USD",
      "param":"General_CurrencyCode"
    },
    {
      "name":"Country",
      "value":"USA",
      "param":"General_CountryName"
    },
    {
      "name":"Sector",
      "value":"Technology",
      "param":"General_Sector"
    },
    {
      "name":"Exchange",
      "value":"NASDAQ",
      "param":"General_Exchange"
    },
    {
      "name":"General Industry",
      "value":"Software-Infrastructure",
      "param":"General_Industry"      
    }
  ];

const STOCK_DETAILS_KEY_INDICATORS:any = 
  [
    {
      "name":"Country",
      "value":"USA",
      "param":"General_CountryName"
    },
    {
      "name":"Sector",
      "value":"Technology",
      "param":"General_Sector"
    },
    {
      "name":"Industry",
      "value":"Software-Infrastructure",
      "param":"General_Industry"      
    },
    {
      "name":"Market Cap",
      "value":"2,256 Billion",
      "param":"Highlights_MarketCapitalization",
      "type":"decimal"
    },
    {
      "name":"Average Volume",
      "value":"1,000,000,000",
      "param":"avg_volume",
      "type":"decimal"
    },
    {
      "name":"ROE",
      "value":"0.4708",
      "param":"Highlights_ReturnOnEquityTTM"
    },
    {
      "name":"ROA",
      "value":"0.1376",
      "param":"Highlights_ReturnOnAssetsTTM"
    },    
    {
      "name":"PE",
      "value":"37.2932",
      "param":"Highlights_PERatio"
    },
    {
      "name":"Forward PE",
      "value":"33.67",
      "param":"Valuation_ForwardPE"
    },
    {
      "name":"PEG",
      "value":"2.78",
      "param":"Highlights_PEGRatio"
    },
    {
      "name":"Next Earnings Date",
      "value":"2021-10-25",
      "param":"Highlights_Next_Reported_Earnings_Date"
    },
    {
      "name":"Recent Earnings Date",
      "value":"2021-07-27",
      "param":"Highlights_Recent_Reported_Earnings_Date"
    },    
    {
      "name":"Revenue Growth",
      "value":"0.142",
      "param":"revenue_growth_cagr_5y",
      "type":"percentage"
    },
    {
      "name":"Income Growth",
      "value":"0.142",
      "param":"income_growth_cagr_5y",
      "type":"percentage"
    },
    {
      "name":"Estimated Revenue Year",
      "value":"216,745,000,000",
      "param":"Highlights_Revenue_Estimate_1y",
      "type":"decimal"
    },
    {
      "name":"Actual EPS (latest quarter)",
      "value":"2.17",
      "param":"Highlights_Actual_EPS_1Q"
    },
    {
      "name":"Actual EPS this year",
      "value":"7.97",
      "param":"Highlights_Actual_EPS_1Y"
    },
    {
      "name":"EPS Surprise",
      "value":"12.7156",
      "param":"Highlights_EPS_Surprise_1Q"
    },    
    {
      "name":"Estimate EPS this year (Year 1)",
      "value":"8.8",
      "param":"Highlights_EPSEstimateCurrentYear"
    },
    {
      "name":"Estimated EPS next Year (Year 2)",
      "value":"10.08",
      "param":"Highlights_EPSEstimateNextYear"
    },
    {
      "name":"EPS Growth this year",
      "value":"0.093",
      "param":"Highlights_EPS_Growth_0y",
      "type":"percentage"
    },
    {
      "name":"EPS Growth next year",
      "value":"0.145",
      "param":"Highlights_EPS_Growth_1y",
      "type":"percentage"
    }
  ];

  const STOCK_DETAILS_EPS:any = 
  [
    {
      "name":"Actual EPS (latest quarter)",
      "value":"2.17",
      "param":"Highlights_Actual_EPS_1Q"
    },
    {
      "name":"EPS Surprise",
      "value":"12.7156",
      "param":"Highlights_EPS_Surprise_1Q"
    },
    {
      "name":"Actual EPS this year",
      "value":"7.97",
      "param":"Highlights_Actual_EPS_1Y"
    },
    {
      "name":"Estimate EPS this year (Year 1)",
      "value":"8.8",
      "param":"Highlights_EPSEstimateCurrentYear"
    },
    {
      "name":"Estimated EPS next Year (Year 2)",
      "value":"10.08",
      "param":"Highlights_EPSEstimateNextYear"
    },
    {
      "name":"EPS Growth this year",
      "value":"0.093",
      "param":"Highlights_EPS_Growth_0y"
    },
    {
      "name":"EPS Growth next year",
      "value":"0.145",
      "param":"Highlights_EPS_Growth_1y"
    },
    /*{
      "name":"EPS Growth next 5 years",
      "value":"N/A",
      "param":"abc"
    } */   
  ];

  @Injectable()
  export class StockDetails {
    getStockDetailsGeneral(): StockDetail[] {
      return STOCK_DETAILS_GENERAL; 
    }  
    
    getStockDetailsKeyIndicators(): StockDetail[] {
      return STOCK_DETAILS_KEY_INDICATORS; 
    }    

    getStockDetailsEPS(): StockDetail[] {
      return STOCK_DETAILS_EPS; 
    }    
  }